import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function deleteDevice(accessToken, userId, fbToken) {
    const response = await fetch(`${URL}/users/${userId}/device/`, {
        
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        },
        body: JSON.stringify({
          token: fbToken
        }),
    });
    const res = await response;
    if (checkResponse(res)) {
        return  res;
        }
    
}