export const setUserData = (gettingUserData) => ({
    type: 'SERVER_USER_DATA',
    payload: {
      ...gettingUserData,
    }
  });
  
  export const setNewBasicUserData = (gettingUserData) => ({
    type: 'NEW_BASIC_USER_DATA',
    payload: {
      ...gettingUserData,
    }
  });
  