/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Link,
  Typography,
  colors
} from '@material-ui/core';
// import { useSelector } from 'react-redux';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import axios from 'axios';
import refreshPage from 'utils/refreshPage'

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(1),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      borderColor: "black",
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dropZoneDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 100
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 200
  },
  actions: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
}));

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: 10
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 120,
  height: 120,
  padding: 4,
  boxSizing: 'border-box',
  justifyContent: 'center'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const ProfilePhoto = props => {
  const { className, URL1, currentToken, setLoading, ...rest } = props;
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  //const currentToken = useSelector(state => state.auth.currentToken)
  
  
  function uploadPhotos () { 
    setLoading(true)
    if (files.length > props.MAX_FILES){
        alert("Límite de fotos alcanzado!")
        handleRemoveAll()
        return false
      }
    
    let config = {
      headers: {
        'Authorization': 'Token ' + currentToken
      }
    }
    
    const data = new FormData()
    data.append('file', files[0]);
    
    try {
      axios.post(URL1, data, config).then(res =>  {
        if (res.status === 200) {
            alert("Foto de perfil cargada con éxito")
            setLoading(false)
            refreshPage()
        } else {
            alert("Error", res.status)
            setLoading(false)
        }})
     } catch (error) {
       alert("Hubo un error", error)
       setLoading(false)
     }

    handleRemoveAll()
    return true
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleRemoveAll () {
    setFiles([]);
  };

  const handleDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(element => {
      var reader = new FileReader();
      reader.onload = (event) => {
        setFiles(files => [...files].concat(Object.assign(element, 
          {base64: event.target.result}, 
          {preview: URL.createObjectURL(element)}
           )))
      }
      reader.readAsDataURL(element);
    });
  }
  , []);
  
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/*',
    onDrop : handleDrop,
    maxSize: props.MAX_SIZE
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
        />
      </div>
    </div>
  ));

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        <div className={classes.dropZoneDiv}>
          <AddAPhotoIcon fontSize="large"/> 
          <Typography
            className={classes.info}
            color="textSecondary"
            variant="body1"
          >
            {props.MSG_PHOTOS1}  <Link underline="always" color='black'>ACA</Link>{' '}
            {props.MSG_PHOTOS2}
          </Typography>
        </div>
      </div>
      <aside style={thumbsContainer}>
        {thumbs} 
      </aside>

      {files.length > 0 && (
        <Fragment>
          <div className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleRemoveAll}
            >
              {props.REM_PHOTOS}
            </Button>


            <Button
              color="primary"
              variant="contained"
              onClick={uploadPhotos}
            >
              {props.ADD_PHOTOS}
            </Button>
            

          </div>
        </Fragment>
      )}
    </div>
  );
};

ProfilePhoto.propTypes = {
  className: PropTypes.string
};

export default ProfilePhoto;
