import palette from "../palette";

export default {
  day: {
    color: palette.text.secondary,
  },
  daySelected: {
    backgroundColor: palette.personal.main,
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.2)",
      color: "black",
    },
  },
  dayDisabled: {
    color: "rgba(0,0,0,0.3)",
  },
  current: {
    color: palette.personal.main,
  },
};
