import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getHistoryUser(accessToken, userId, params) {
  try {
    let query = null;
    if (params) {
      const query_params = new URLSearchParams(params);
      query = query_params.toString();
    }
    const response = await fetch(`${URL}/users/${userId}/events/${query ? `?${query}` : ""}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    });
    const res = await response.json();

    if (checkResponse(response)) {
      return res;
    }
  } catch (error) {
    alert(error);
    return [];
  }
}
