import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function addSpecialty(specialty_name) {
    const accessToken = localStorage.getItem('currentToken')
    const response = await fetch(`${URL}/specialties/`, {
        
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        },
        body: JSON.stringify({
            specialty_name: specialty_name
        })
    });
    const res = await response.json();
    if (checkResponse(response)) {
        return  res;
        }
}