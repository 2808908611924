import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import * as d3 from 'd3';
import * as lodash from 'lodash';

import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Grid,
    Typography,
    IconButton,
    InputAdornment,
    useMediaQuery,
  } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  root: {
  },
  container: {
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    margin: 0,
    padding: "5px 0"
  },
  searchBar: {
  },
  card: {
    boxShadow: '0 2px 2px 0 #BDC9D7',
  },
  imgDiv: {
  },
  avatar: {
    minWidth: '60px',
    width: '60px',
    height: '60px',
  },
  tooltip: {
    position: 'absolute',
    display: 'none',
    backgroundColor: '#AAAAAA',
    color: 'white',
    paddingLeft: '1%',
    paddingRight: '1%',
    borderRadius: '5px',
    fontFamily: 'roboto'
  },
  grid: {
    display: 'flex',
  },
  downloadButton:{
    padding: "0 0 12px 4px",
    color: 'black',
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.personal.main
    },
  },
  dialog: {
    minWidth: '50vh'
  },
  dialogButtons: {
    backgroundColor: 'white',
    color: 'black',
    "&:hover": {
      backgroundColor: "#802e87",
      color: 'white'
    },
  },
  loader: {
    maxWidth: theme.spacing(4),
    maxHeight: theme.spacing(4),
    color: theme.palette.personal.main,
    "&:hover": {
      color: 'white'
    }
  },
}));

const initials = (name) => {
  const initials = name.split(" ").map((w)=>w[0]).filter(w => w.toUpperCase() == w)
  if (initials.length < 3){
    return initials.join("");
  } else {
    return initials.slice(0, 2).join("");
  }
}

const filterBarUsers = (usersObject) => {
  const filteredKeys = Object.keys(usersObject).filter((k) => ["bar", "arc"].includes(k.substring(0, 3)));

  const filtered = filteredKeys.reduce((obj, key) => {
                                          obj[key] = usersObject[key];
                                          return obj;
                                      }, {});
  return filtered
}

const filterBodyUsers = (usersObject) => {
  const filteredKeys = Object.keys(usersObject).filter((k) => k.substring(0, 4) === "body");

  const filtered = filteredKeys.reduce((obj, key) => {
                                          obj[key] = usersObject[key];
                                          return obj;
                                      }, {});
  return filtered
}

const tooltipPos = (element) => {
  const bbox = element.getBoundingClientRect();
  const divBox = d3.select(`div#usersListContainer`).node().getBoundingClientRect();
  var posX = (bbox.x),
      posY = (bbox.y + bbox.height + 10);
  return [posX, posY];
}

const commonElements = (array1, array2) => {
  let result = []
  array1.forEach(d => {
      if (array2.indexOf(d) >= 0) {
          result.push(d);
      }
  })
  return result
}

const nestedCommonElements = (array) => {
  let result = [];
  if (array.length){
    result = array[0]
    array.forEach((arr) => {
        result = commonElements(result, arr);
    })
  }
  return result;
}

const PatientsList = (props) => {

  const { patients, 
          selectedUsers, 
          setSelectedUsers,
          timeRange,
          getCSV,
          getCSVAdmin,
          // eslint-disable-next-line
          ...rest} = props;
  
  const id = localStorage.getItem('id')
  const accessToken = localStorage.getItem('currentToken')
  const role = localStorage.getItem('role');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchInput, setSearchInput] = useState('');
  const [loadingAllData, setLoadingAllData] = useState(false);
  const [loadingChartData, setLoadingChartData] = useState(false);
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState({});
  const [innerSelectedUsers, setInnerSelectedUsers] = useState([]);

  const classes = useStyles();

  async function handleSearch(event){
    setSearchInput(event.target.value);
  }

  const handleOpen = () => {
    if (innerSelectedUsers.length) {
      const newParams = {
        patients: innerSelectedUsers.toString(), 
        range: timeRange,
      };
      setParams(newParams);
    }
    else {
      const newParams = {
        range: timeRange,
      }
      setParams(newParams);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleDownload(chart) {
    if (chart === 'True')
    {
      setLoadingChartData(true);
    } else {
      setLoadingAllData(true);
    }
    if (role === 'admin') {
      await getCSVAdmin(accessToken, { ...params, chart: chart }, "autorreportes-pacientes");
    } else {
      await getCSV(accessToken, id, { ...params, chart: chart }, "autorreportes-pacientes");
    }
    if (chart === 'True')
    {
      setLoadingChartData(false);
    } else {
      setLoadingAllData(false);
    }
    setOpen(false);
  };

  useEffect(() => {

    const filterSearched = (user) => {
      const normalizedSearch = searchInput.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
      const normalizedUser = user.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
      return !searchInput || normalizedUser.includes(normalizedSearch);
    }

    const outerSelected = lodash.cloneDeep(selectedUsers.patients);
    let selectedBars = nestedCommonElements(Object.values(selectedUsers.logs))
    selectedBars = selectedBars.map((l) => {
      return patients.find((u) => u.logs.includes(l)).id
    })

    let innerSelected = [];
    if (Object.keys(outerSelected).includes('patientsList')) {
      innerSelected = lodash.cloneDeep(outerSelected['patientsList'])
      delete outerSelected.patientsList;
    }
    const selectedBody = Object.values(filterBodyUsers(outerSelected));

    let selectedValues = [];

    if (selectedBody.length) {
      const bodyData = lodash.flattenDeep(selectedBody);
      innerSelected = innerSelected.concat(bodyData);
    }

    setInnerSelectedUsers(innerSelected);

    if (selectedBars.length) {
      selectedValues = selectedValues.concat(selectedBars);
    }

    const selected = selectedValues.filter((e, i) => selectedValues.indexOf(e) === i);

    let filtered;

    if (selected.length) {
        filtered = patients.filter((d) => selected.includes(d.id) && filterSearched(d));
        
    } else {
        filtered = patients.filter((d) => filterSearched(d));
    }
    

    const container = d3.select(`.${classes.container}`);

    const mouseOver = (e, d) => {
      d3.select(`#avatar-${d.id}`)
          .style('opacity', 0.5)
          .attr('opacity', 0.5);

      const [x, y] = tooltipPos(e.currentTarget)

      d3.select(`.${classes.tooltip}`)
            .style('display', 'block')
            .style('left', `${x}px`)
            .style('top', `${y}px`)
            .select('p')
                .html(`${d.name}`)
    }

    const mouseLeave = (e, d) => {
      d3.select(`#avatar-${d.id}`)
          .style('opacity', 1)
          .attr('opacity', 1);

      d3.select(`.${classes.tooltip}`)
          .style('display', 'none')
    }

    const click = (e, d) => {
      const newSelection = lodash.cloneDeep(selectedUsers);
      if (!Object.keys(newSelection.patients).includes('patientsList')) {
        newSelection.patients.patientsList = [];
      }
      if (innerSelected.includes(d.id)) {
        newSelection.patients.patientsList = innerSelected.filter((p) => p !== d.id)
      } else {
        newSelection.patients.patientsList.push(d.id)
      }
      setSelectedUsers(newSelection)
    }

    container.selectAll(`div.${classes.imgDiv}`)
        .data(filtered, (d) => d.id)
        .join(
          (enter) => {
            const havePic = enter.filter((d) => d.photoURL);
            const noPic = enter.filter((d) => !d.photoURL);

            const picDiv = havePic.append('div')
                    .style('order', d => -innerSelected.indexOf(d.id))
                    .attr('class', classes.imgDiv)
                    .style('width', '0vw')
                    .style('height', '4vw')
                    .style('border', (d) => {
                      if ( innerSelected.includes(d.id) ) {
                        return '3px solid #802e87'
                      } else {
                        return null
                      }
                    })
                    .style('border-radius', ' 50%')

            const picImg = picDiv.append('img')
                            .attr('id', (d) => `avatar-${d.id}`)
                            .attr('display', 'block')
                            .attr('height', '100%')
                            .attr('width', '100%')
                            .style('border-radius', '50%')
                            .attr('src', (d) => d.photoURL)
            
            picDiv.transition()
                    .style('width', '4vw')
                    .style('margin-right', '0.5vw')

            const npDiv = noPic.append('div')
                            .style('order', d => -innerSelected.indexOf(d.id))
                            .attr('class', classes.imgDiv)
                            .style('border', (d) => {
                              if ( innerSelected.includes(d.id) ) {
                                return '3px solid #802e87'
                              } else {
                                return null
                              }
                            })
                            .style('border-radius', ' 50%')
                            .style('width', '0vw')
                            .style('height', '4vw')

            const npsvg = npDiv.append('svg')
                          .attr('id', (d) => `avatar-${d.id}`)
                          .attr('height', '100%')
                          .attr('width', '100%')
                          .attr('viewBox', '0 0 100 100')
                          

            npsvg.append('circle')
                  .attr('cx', 50)
                  .attr('cy', 50)
                  .attr('r', 50)
                  .attr('fill', '#AAAAAA')
            npsvg.append('text')
                  .attr('x', 50)
                  .attr('y', 50)
                  .attr('dy', '0.3em')
                  .text((d) => initials(d.name))
                  .attr('font-family', 'roboto')
                  .attr('font-size', '2em')
                  .attr('text-anchor', 'middle')
                  .attr('max-witdh', '3em')

            npDiv.style('min-width', '4vw')
                    .transition()
                    .style('width', '4vw')         
                    .style('margin-right', '0.5vw')
                    
            picDiv.style('min-width', '4vw')
                    .transition()
                    .style('width', '4vw')         
                    .style('margin-right', '0.5vw')

            picDiv.on('mouseenter', mouseOver)
            npDiv.on('mouseenter', mouseOver)

            picDiv.on('mouseleave', mouseLeave)
            npDiv.on('mouseleave', mouseLeave)

            picDiv.on('click', click)
            npDiv.on('click', click)
          }, 
          (update) => {
            update.style('border', (d) => {
                if ( innerSelected.includes(d.id) ) {
                  return '3px solid #802e87'
                } else {
                  return null
                }
              });

            update.transition()
              .style('order', d => -innerSelected.indexOf(d.id))
            update.on('click', click)
          },
          (exit) => {
            exit.transition()
              .style('opacity', 0)
              .remove()
          }
        )
  // eslint-disable-next-line
  }, [patients, selectedUsers, searchInput])

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container >
          <Grid item
            md={8}
            sm={8}
            xs={12}
            className={classes.grid}
          >
            <Typography
              // component="h1"
              variant="h5"
            >
              Mis Pacientes
            </Typography>
            <IconButton
                className={classes.downloadButton}
                onClick={() => {
                  handleOpen();
                }}
                >
                <GetAppIcon />
            </IconButton>
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={() => handleClose()}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">{"Descarga Excel de Pacientes"}</DialogTitle>
              <DialogContent className={classes.dialog}>
                <DialogContentText>
                  ¿Cuáles datos deseas descargar?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Grid container justify='space-evenly' direction='column' spacing={2}>
                  <Grid item xs={12}>
                    <Button 
                      fullWidth 
                      autoFocus 
                      className={classes.dialogButtons}
                      onClick={() => handleDownload('False')} 
                      variant="outlined"
                      startIcon={loadingAllData ? false : <GetAppIcon />}
                    >
                      {loadingAllData ? 
                        <CircularProgress className={classes.loader} />
                        : "Todos los datos"
                      }
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button 
                      fullWidth 
                      autoFocus 
                      className={classes.dialogButtons}
                      onClick={() => handleDownload('True')} 
                      variant="outlined"
                      startIcon={loadingChartData ? false : <GetAppIcon />}
                    >
                      {loadingChartData ? 
                        <CircularProgress className={classes.loader} />
                        : "Solo los de los gráficos mostrados"
                      }
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button 
                      fullWidth 
                      autoFocus 
                      className={classes.dialogButtons}
                      onClick={() => handleClose()} 
                      variant="outlined"
                      startIcon={<ArrowBackIcon />}
                    >
                      Volver
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item
            md={4}
            sm={4}
            xs={12}
            className={classes.searchBar}
          >
            <TextField
              fullWidth
              placeholder="Buscar Paciente..."
              onChange={handleSearch}
              variant="outlined"
              color="primary"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <div id="usersListContainer" className={classes.container}>
          <div className={classes.tooltip} id="users-tooltip">
            <p></p>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default PatientsList;
