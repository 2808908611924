/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
  Grid, 
  Fab,
  colors,
  CircularProgress,
 } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

import { 
  getStatsSummary, 
  getStatsOverview,
  getSelectedCharts,
  getCSV,
  getCSVProfessional } from 'utils/fetch';

import { Page } from 'components';
import {
  Header,
  BodyGraph,
  PatientsList,
  PatientsStats
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    '& > *': {
      height: '100%'
    }
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(6),
    backgroundColor: '#802e87',
    color: 'white',
    '&:hover': {
      backgroundColor: colors.blue[900],
    }
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
  loading: {
    width: '100%',
    display: 'flex',
    marginTop: '20%',
    "& .MuiCircularProgress-colorPrimary": {
      color: '#802e87',
      margin: 'auto',
    }
  },
}));

const DashboardAnalytics = () => {
  const classes = useStyles();

  const accessToken = localStorage.getItem('currentToken')
  const id = localStorage.getItem('id')
  const role = localStorage.getItem('role')
  
  const initialSelection = {
    patients: {},
    logs: {}
  }

  const [ selectedUsers, setSelectedUsers ] = useState(initialSelection);
  const [ timeRange, setTimeRange ] = useState('today');
  const [ selectedCharts, setSelectedCharts ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  const [ data, setData ] = useState({
      "patients": [],
      "bodymap": [],
    })
  const [patientsCount, setPatientsCount] = useState(0);


  async function fetchData(time_value) {
    if (role === 'admin'){
      var { stats } = await getStatsOverview(accessToken, time_value);
    } else{
      // eslint-disable-next-line no-redeclare
      var { stats } = await getStatsSummary(accessToken, id, time_value);
    }
    setData(stats);
    if (loading) {
      setLoading(false);
    }
    
  }

  async function updateSelection(newobject) {
    setSelectedUsers(newobject);
  }

  async function resetSelection() {
    setSelectedUsers(initialSelection);
  }

  async function fetchSelectedCharts() {
    const { res } = await getSelectedCharts(id);
  
    setSelectedCharts(res)
  }

  useEffect(() => {
    fetchSelectedCharts();
  }, [])

  useEffect(() => {
    setPatientsCount(data.patients.length)
  }, [data, loading])

  useEffect(() => {
    fetchData(timeRange);
    resetSelection();
  }, [timeRange])

  useEffect(() => {
    fetchData(timeRange);
  }, [selectedCharts])

  if (loading) {
    return (
      <Page className={classes.loading}>
        <CircularProgress/>
      </Page>
    )
  } else {
    return (
      <Page className={classes.root}>
        <Fab
          className={classes.fab}
          onClick={resetSelection}
          variant="extended"
          size="medium"
          title={"Resetear Todas las Selecciones"}
        >
          <RefreshIcon className={classes.fabIcon} />
          Resetear
        </Fab>
        <Header 
          timeRange={timeRange} 
          setTimeRange={setTimeRange}
          selectedCharts={selectedCharts}
          setSelectedCharts={setSelectedCharts}
          patientCount={patientsCount}
          resetSelection={resetSelection}
        />
        <Grid
          className={classes.container}
          container
          spacing={3}
        >
          
        <Grid item xs={12}>
          <PatientsList 
            patients={data.patients}
            selectedUsers={selectedUsers} 
            setSelectedUsers={updateSelection} 
            timeRange={timeRange}
            getCSV={getCSVProfessional}
            getCSVAdmin={getCSV}
          />
        </Grid>
        
          <Grid
            item
            xs={12}
          >
            <PatientsStats 
              selectedUsers={selectedUsers} 
              setSelectedUsers={updateSelection} 
              graphsData={data}
              selectedGraphs={selectedCharts}
              patientCount={patientsCount}
            />
          </Grid>
        
          <Grid item xs={12}>
            <BodyGraph 
              selectedUsers={selectedUsers} 
              setSelectedUsers={updateSelection} 
              bodyData={data.bodymap}
              patientCount={patientsCount}
            />
          </Grid>
        </Grid>
      </Page>
    );
  }
};

export default DashboardAnalytics;
