import { URL } from '../url';
import { checkResponse} from "../checkResponse";

export async function getArticles(accessToken, id) {
    try {
        const response = await fetch(`${URL}/users/${id}/articles/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
        const res = await response.json();
        
        if (checkResponse(response)) {
            return  res;
            }
    } catch (error) {
        console.log(error)
        alert(error)
        return null;
    }
}