import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function getAllTags() {
    const accessToken = localStorage.getItem('currentToken')
    const response = await fetch(`${URL}/tags/`, {
        
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        }
    });
    const res = await response.json();

    if (checkResponse(response)) {
        return {response, res};
        }
    
    
}