import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { default as BarChart } from './BarChart';
import { default as PieChart } from './PieChart';

import {
    Grid,
    CircularProgress,
    Card,
    Typography
  } from '@material-ui/core';

  const useStyles = makeStyles(theme => ({
    root: {

    },
    graphContainer: {},
    loadingCard: {
      width: '100%',
      height: '100%',
    },
    graphTitle: {
      textAlign: 'center',
      marginTop: '10px',
      marginBottom: '10px'
    },
    loadingContainer: {
      display: 'flex',
      "& .MuiCircularProgress-colorPrimary": {
        color: '#802e87',
        margin: '20% auto',
      }
    }
    

  }));

  const PatientsStats = (props) => {

    const {
      selectedUsers, 
      setSelectedUsers, 
      graphsData,
      selectedGraphs, 
      patientCount} = props;

    const classes = useStyles();

    return (
        <Grid container spacing={2}>
          {
            selectedGraphs.map((chart) => {
              if (graphsData.hasOwnProperty(chart.stat) && chart.chart_type === "bar") {
                return <Grid item 
                key={chart.id}
                lg={4}
                xl={4}
                xs={12}
                className={classes.graphContainer}
              >
                <BarChart 
                  data={graphsData[chart.stat]}
                  name={chart.stat} 
                  patientCount={patientCount} 
                  selectedUsers={selectedUsers} 
                  setSelectedUsers={setSelectedUsers} 
                  title={chart.title}
                />
              </Grid>
              } else if (graphsData.hasOwnProperty(chart.stat) && chart.chart_type === "pie") {
                return <Grid item 
                key={chart.id}
                lg={4}
                xl={4}
                xs={12}
                className={classes.graphContainer}
              >
                <PieChart 
                  data={graphsData[chart.stat]}
                  name={chart.stat} 
                  patientCount={patientCount} 
                  selectedUsers={selectedUsers} 
                  setSelectedUsers={setSelectedUsers} 
                  title={chart.title}
                />
              </Grid>
              } else {
                return <Grid item 
                key={chart.id}
                lg={4}
                xl={4}
                xs={12}
                className={classes.graphContainer}
              >
                <Card
                  className={classes.loadingCard}
                >
                  <Typography
                      component="h2"
                      gutterBottom
                      variant="overline"
                      className={classes.graphTitle}
                  >
                  {chart.chart_name}
                  </Typography>
                  <div className={classes.loadingContainer}>
                    <CircularProgress size={'20%'}/>
                  </div>
                  
                </Card>
              </Grid>
              }
            })
          }
        </Grid>
    )
  }

  export default PatientsStats;
  
