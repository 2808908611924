import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function deleteTag(tag_id, accessToken, user_id) {
    const response = await fetch(`${URL}/users/${user_id}/tags/`, {
        
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        },
        body: JSON.stringify({
            tagID: tag_id
        })
    });
    if (checkResponse(response)) {
        return  response;
        }
    
}