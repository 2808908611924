import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function getAllSpecialties() {
    const accessToken = localStorage.getItem('currentToken')
    const response = await fetch(`${URL}/specialties/`, {
        
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    const res = await response.json();

    if (checkResponse(response)) {
        return {response, res};
        }
    
}
