import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function asignarTemplateTag(template, tags) {
    const accessToken = await localStorage.getItem('currentToken')
    const response = await fetch(`${URL}/templates/${template}/tags/`, {
        
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        },
        body: JSON.stringify({"tags": tags})
    
    });
    
    const res = await response.json();
    if (checkResponse(response)) {
        return  {res, response};
        }
}