import { URL } from '../url'

export async function registerAccount(email, tipo, diagnostico) {
    const accessToken = await localStorage.getItem('currentToken')
    const lista1 = [email]
    const lista2 = [tipo]
    const lista3 = [diagnostico]
    const bodyy = {"emails": lista1, "accounts": lista2, "diagnoses": lista3}
    const response = await fetch(`${URL}/users/new-account/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        },
        body: JSON.stringify(bodyy)
    });

    const res = await response.json();
    return res;
    
}
