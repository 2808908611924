/* eslint-disable no-unused-vars */
import React, { useState,  useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Fab,
  CircularProgress,
  colors,
  Badge,
  Menu,
  Card,
  CardContent,
  Typography,
  fade,
} from '@material-ui/core';
import { NotificationsNone, Notifications } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';
import SaveIcon from '@material-ui/icons/Save';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import EventIcon from '@material-ui/icons/Event';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import axios from 'utils/axios';
import { PricingModal} from 'components';
import { logOut, setUnreadedNot } from 'actions';
import { deleteDevice } from 'utils/delete';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: true
  },
  flexGrow: {
    flexGrow: 1
  },
  buttonSuccess: {
    backgroundColor: '#802e87',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: '#802e87',
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  loader: {
    position: "absolute",
    bottom: "5%",
    right: "5%",
    zIndex: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: "#802e87",
    '&:hover': {
      backgroundColor: colors.blue[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    color: "black"
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  mainIcon: {
    height: 40,
    marginLeft: theme.spacing(3),
  },
  notificationsButton: {
    borderRadius: "100%",
  },
  notificationsIcon: {
    color: theme.palette.personal.main,
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  notificationsBadge: {
    color: theme.palette.personal.main,
  },
  notification: {
    borderColor: "transparent",
    borderRadius: "",
    borderBottomColor: "rgba(0,0,0,0.1)",
  },
  unreadedNotification: {
    backgroundColor: fade(theme.palette.personal.main, 0.1),
    borderRadius: "",
    borderColor: "transparent",
    borderLeftColor: theme.palette.personal.main,
    borderWidth: 3,
    borderStyle: "solid",
  }
}));
const role = localStorage.getItem('role');


const TopBar = props => {
  const { onOpenNavBarMobile, route, className, ...rest } = props;
  const classes = useStyles();
  const { aparecen, cargandon, successn, setAparecen, setCargandon, setSuccessn} = route
  const dispatch = useDispatch();
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const role = localStorage.getItem('role');

  const notifications = useSelector(state => state.notifications.notificationsList);
  const unreadedNotifications = useSelector(state => state.notifications.unreaded);
  const allowedNotifications = useSelector(state => state.notifications.allowed);
  const [openMenu, setOpenMenu] = useState(null);

  const handleLogout = async () => {
    //history.push('/auth/login');
    if(window.confirm('¿Estas seguro/a que deseas cerrar sesión?')){
      const fbToken = localStorage.getItem("fbToken");
      const userId = localStorage.getItem("id");
      const accessToken = localStorage.getItem("currentToken");
      if (fbToken) {
        await deleteDevice(accessToken, userId, fbToken);
      };
      localStorage.clear();
    dispatch(logOut());
  };
  };
  const buttonClassname = clsx({
    [classes.buttonSuccess]: successn,
  });

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };
  const handleButtonClick = () => {
    if (successn) {
      setSuccessn(false);
      setAparecen(false)
      setCargandon(true);
      
    }
  };
  const handleWebPage = () => {
    var toShop =  "https://agenda.ucchristus.cl/"
    window.open(toShop);
  };

  const handleClickNotMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseNotMenu = () => {
    setOpenMenu(null);
    notifications.map((n) => {n.new = false});
    dispatch(setUnreadedNot(0));
  }

  const getNotificationsMenu = () => {
    return (
      <div>
        <IconButton
          className={classes.notificationsButton}
          aria-controls="notifications-menu"
          aria-haspopup="true"
          onClick={handleClickNotMenu}
        >
          <Badge
            badgeContent={unreadedNotifications}
            className={classes.notificationsBadge}
          >
            {unreadedNotifications > 0 ? (
              <Notifications className={classes.notificationsIcon} />
            ) : (
              <NotificationsNone className={classes.notificationsIcon} />
            )}
          </Badge>
        </IconButton>
        <Menu
          id="notifications-menu"
          anchorEl={openMenu}
          keepMounted
          open={Boolean(openMenu)}
          onClose={handleCloseNotMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          PaperProps={{
            style: {
              maxHeight: 300,
              minHeight: 300,
              maxWidth: 400,
              minWidth: 400,
            },
          }}
        >
          {notifications.length > 0 ? (
            notifications.map((not) => {
              const className = not.new
                ? classes.unreadedNotification
                : classes.notification;
              return (
                <Card variant="outlined" className={className}>
                  <CardContent>
                    <Typography glutterBottom variant="h5" component="h2">
                      {not.title}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {not.body}
                    </Typography>
                  </CardContent>
                </Card>
              );
            })
          ) : (
            <Card variant="outlined" className={classes.notification}>
              <CardContent>
                <Typography variant="body2" component="p">No hay notificaciones</Typography>
              </CardContent>
            </Card>
          )}
        </Menu>
      </div>
    );
  };

  const popularSearches = [
    'Centro del dolor',
    'Centro de tratamiento del dolor UC',
    'Admin Pannel',
    'Project',
    'Pages'
  ];
  let button;
  let button2;
  if (role=="patient") {
    button = <Button className={classes.trialButton} component={RouterLink} variant="contained" to='/autoreporte'> <ListAltIcon className={classes.trialIcon} />Autoreporte</Button>;
    button2=<Button className={classes.trialButton}  variant="contained" onClick={handleWebPage}><EventIcon className={classes.trialIcon} /> Reserva Médica</Button>;

  } else {
    button = allowedNotifications ? getNotificationsMenu() : "";
    button2 =  <Button className={classes.trialButton} component={RouterLink} variant="contained" to='/management/customers'> <PeopleIcon className={classes.trialIcon} />Mis Pacientes</Button>;
  }
  
 
  return (
    <div>
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar>
        <RouterLink to="/" >
          <img className={classes.mainIcon}
            alt="Logo"
            src="/images/logos/logo--red.png"
            
          />
        </RouterLink>
        <div className={classes.flexGrow} />

          <Hidden smDown>
            {button}
          </Hidden>
          <Hidden smDown>
            {button2}
          </Hidden>
          
          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon className={classes.logoutIcon} name='logout' />
            <Hidden mdDown>
            cerrar
            </Hidden>
          </Button>
        
        <Hidden lgUp>
          <IconButton 
            name='navbar'
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <PricingModal
        onClose={handlePricingClose}
        open={pricingModalOpen}
      />
      
    </AppBar>
    {aparecen ? <div className={classes.loader}>
      <div className={classes.wrapper}>
        <Fab
          aria-label="save"
          color="primary"
          className={buttonClassname}
          onClick={handleButtonClick}
        >
          {successn ? <CheckIcon /> : <SaveIcon />}
        </Fab>
        {cargandon && <CircularProgress size={68} className={classes.fabProgress} />}
      </div>
      </div>:<></> }
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
