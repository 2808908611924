import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function createReport(Qid, Pid, accessToken) {
    const response = await fetch(`${URL}/reports/`, {
        
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        },
        body:JSON.stringify({
            questionaryID: Qid,
            patientID: Pid
    })
    });
    const res = await response.json();
    if (checkResponse(response)) {
        return  res;
        }
    
}