import { URL } from '../url';
import { checkResponse } from "../checkResponse";

export async function getStatsRecommender(userID, accessToken) {
    try {
        const response = await fetch(`${URL}/users/${userID}/stats-recommender/`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        },
        });
        const res = await response.json();
        if (checkResponse(response)) {
            return  res;
        }
    } catch (error) {
        alert(error)
        return null;
    }
};
