/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';

import {
    Typography,
    Card,
  } from '@material-ui/core';

import * as d3 from 'd3';
import * as lodash from 'lodash';

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: '0 2px 2px 0 #BDC9D7',
    },
    graphCard: {
        margin: "5%",
        boxShadow: '0 2px 2px 0 #BDC9D7',
    },
    graphTitle: {
      textAlign: 'center',
      marginTop: '10px',
    },
    tooltip: {
      position: 'absolute',
      display: 'none',
      backgroundColor: '#AAAAAA',
      color: 'white',
      paddingLeft: '1%',
      paddingRight: '1%',
      borderRadius: '5px',
      fontFamily: 'roboto'
    }
  }));


const WIDTH = 400,
      HEIGHT = 300;

const margins = { top: 10, bottom: 20, left: 0, right: 0 };

const width = WIDTH - margins.left - margins.right,
      height = HEIGHT - margins.top - margins.bottom

const formatID = (value) => {
    if (typeof value === 'string' || value instanceof String) {
        return value.replace(/\s/g, '');
    } else {
        return value;
    }
}

const filter = (element, selectionIds, selectionLogs) => {
    if (selectionLogs.length && selectionIds.length){
        return {
            value: element.value,
            logs: element.logs.filter((l) => selectionIds.includes(l.user_id) && selectionLogs.includes(l.log_id)),
        }
    } else if (selectionLogs.length) {
        return {
            value: element.value,
            logs: element.logs.filter((l) => selectionLogs.includes(l.log_id)),
        }
    } else if (selectionIds.length) {
        return {
            value: element.value,
            logs: element.logs.filter((l) => selectionIds.includes(l.user_id)),
        }
    } 
}

const filterBarLogs = (logsObject, name) => {
    const filteredKeys = Object.keys(logsObject).filter((k) => ["bar", "arc"].includes(k.substring(0, 3)) && k.indexOf(name) < 0);

    const filtered = filteredKeys.reduce((obj, key) => {
                                            obj[key] = logsObject[key];
                                            return obj;
                                        }, {});
    return filtered
}

const filterBodyUsers = (usersObject) => {
    const filteredKeys = Object.keys(usersObject).filter((k) => k.substring(0, 4) === "body");

    const filtered = filteredKeys.reduce((obj, key) => {
                                            obj[key] = usersObject[key];
                                            return obj;
                                        }, {});
    return filtered
}

const tooltipPos = (element, name) => {
    const bbox = element.getBBox();
    const svgBox = d3.select(`svg#${name}`).node().getBoundingClientRect();
    
    var posX = (bbox.x + bbox.width/2),
        posY = (bbox.y + bbox.height/2);
    
    posX = svgBox.x + (posX / WIDTH) * svgBox.width;
    posY = svgBox.y + (posY / HEIGHT) * svgBox.height;
    return [posX, posY];
  }

const commonElements = (array1, array2) => {
    let result = []
    array1.forEach(d => {
        if (array2.indexOf(d) >= 0) {
            result.push(d);
        }
    })
    return result
}

const filterCriteria = (selected, name) => {
    let bodyIds = Object.values(filterBodyUsers(selected.patients));

    const barLogs = Object.values(filterBarLogs(selected.logs, name));
    
    let selectedLogs = [];
    if (barLogs.length){
        selectedLogs = barLogs[0]
        barLogs.forEach((arr) => {
            selectedLogs = commonElements(selectedLogs, arr);
        })
    }
    
    let listIds;
    if (Object.keys(selected.patients).includes('patientsList')) {
        listIds = Object.values(selected.patients.patientsList);
    } else {
        listIds = [];
    };

    bodyIds = lodash.flattenDeep(bodyIds);

    let selectedIds;

    if (bodyIds.length && listIds.length) {
        selectedIds = commonElements(listIds, bodyIds);
    } else {
        selectedIds = bodyIds.concat(listIds)
    }

    selectedIds = lodash.flattenDeep(selectedIds)
    selectedIds = selectedIds.filter((e, i) => selectedIds.indexOf(e) === i);
    selectedLogs = selectedLogs.filter((e, i) => selectedLogs.indexOf(e) === i);
    return [selectedIds, selectedLogs]
}

const PieChart = (props) => {
    const {data, 
           name, 
           patientCount,
           selectedUsers,
           setSelectedUsers,
           title} = props;

    const classes = useStyles();

    useEffect(() => {

        const selectedKeys = Object.keys(selectedUsers.logs);

        const [selectedIds, selectedLogs] = filterCriteria(selectedUsers, name);

        let filtered;

        if (selectedIds.length || selectedLogs.length) {
            filtered = data.map((d) => filter(d, selectedIds, selectedLogs));
        } else {
            filtered = data;
        }

        const yValues = filtered.map((d) => d.logs.length)

        const svg = d3.select(`#${name}`);

        if (!yValues.filter((x) => x).length) {
            d3.select(`.no-data-${name}`)
                .attr("display", "visible")
                .style('font-family', 'roboto')
            svg.select(".labelContainer")
                .attr("display", "none")
            
        } else {
            d3.select(`.no-data-${name}`).attr("display", "none")
            svg.select(".labelContainer")
                .attr("display", "visible")
        }

        const g = svg.select('g.container');

        const pie = d3.pie()
            .padAngle(Math.PI/180)
            .value(d => d.logs.length)


        const radius = Math.min(width, height) / 2,
              arcs = pie(filtered),
              arc = d3.arc()
                        .innerRadius(radius * 0.5)
                        .outerRadius(radius),
              arcLabel = d3.arc()
                            .innerRadius(radius * 0.75)
                            .outerRadius(radius * 0.75);

        const mouseOver = (e, d) => {

            const datum = d.data;
            const [ cx, cy ] = tooltipPos(e.currentTarget, name);

            const patients = datum.logs.length
            const plural = patients === 1 ? 'reporte' : 'reportes';
            d3.select(`#${name}-tooltip`)
                .style('display', 'block')
                .style('left', `${cx}px`)
                .style('top', `${cy}px`)
                .select('p')
                    .html(`${patients} ${plural}`)
            d3.select(`#${name}-arc-${formatID(datum.value)}`)
                .style('opacity', 0.5)
                .attr('opacity', 0.5);
        }
      
        const mouseLeave = (e, d) => {
            d3.select(`#${name}-arc-${formatID(d.data.value)}`)
                .style('opacity', 1)
                .attr('opacity', 1);
            
            d3.select(`#${name}-tooltip`)
                .style('display', 'none')
        }

        const click = (_, d) => {
            const tag = `arc-${name}${formatID(d.data.value)}`;
            if (Object.keys(selectedUsers.logs).includes(tag)) {       
                const newSelected = lodash.cloneDeep(selectedUsers);
                delete newSelected.patients[tag];
                delete newSelected.logs[tag];
                setSelectedUsers(newSelected);
            } else {           
                const newSelected = lodash.cloneDeep(selectedUsers);
                const fulldatum = data.find((dt) => dt.value === d.data.value);
                newSelected.patients[tag] = fulldatum.logs.map((l) => l.user_id);
                newSelected.logs[tag] = fulldatum.logs.map((l) => l.log_id);
                setSelectedUsers(newSelected);
            }
        }

        g.selectAll("path.arc")
            .data(arcs, (d) => d.data.value)
            .join(
                (enter) => {
                    enter.append('path')
                        .attr('class', 'arc')
                        .attr("d", arc)
                        .attr("id", (d) => `${name}-arc-${formatID(d.data.value)}`)
                        .attr("fill", (d) => {
                            const tag = `arc-${name}${formatID(d.data.value)}`;
                            if (selectedKeys.includes(tag)) {
                                return 'steelblue'
                            } else if (d.data.value === "Sí") {
                                return '#802e60'
                            } else {
                                return '#802e95'
                            }
                        })
                        .on("mouseover", mouseOver)
                        .on("mouseleave", mouseLeave)
                        .on("click", click)
                
                    },
                (update) => {
                    update.attr("d", arc)
                    update.attr("fill", (d) => {
                                const tag = `arc-${name}${formatID(d.data.value)}`;
                                if (selectedKeys.includes(tag)) {
                                    return 'steelblue'
                                } else if (d.data.value === "Sí") {
                                    return '#802e60'
                                } else {
                                    return '#802e95'
                                }
                            })

                    update.on("click", click)
                          .on("mouseover", mouseOver)
                          .on("mouseleave", mouseLeave);
                },
                (exit) => {
                    exit.remove()
                }
            )
        svg.select(`.labelContainer`)
            .attr('font-family', 'roboto')
            .attr('text-anchor', 'middle')
            .selectAll("text")
            .data(arcs, d => d.data.value)
            .join((enter) => {
                enter.append("text")
                .attr("class", "label")
                .attr("transform", d => `translate(${arcLabel.centroid(d)})`)
                .call(text => text.append("tspan")
                    .attr("font-weight", "bold")
                    .style("fill", "white")
                    .text(d => d.value ? d.data.value : ""))
            }, (update) => {
                update.transition().attr("transform", d => `translate(${arcLabel.centroid(d)})`)
                update.select("tspan")
                        .text(d => d.value ? d.data.value : "")
            }, (exit) => {
                exit.remove()
            })
            
            
    }, [data, selectedUsers, patientCount])

    return (
        <Card
            className={classes.card}
        >
            <Typography
                component="h2"
                gutterBottom
                variant="overline"
                className={classes.graphTitle}
            >
            {title}
            </Typography>
            <Card
                className={classes.graphCard}
            >
                <div className={classes.graphContainer}>
                    <div className={classes.tooltip} id={`${name}-tooltip`}>
                    <p></p>
                    </div>
                    <svg id={name} version="1.0" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox={`${-WIDTH/2} ${-HEIGHT/2} ${WIDTH} ${HEIGHT}`} preserveAspectRatio="xMidYMid meet">
                        <g className='container' transform={`translate(${margins.left}, ${margins.top})`}></g>
                        <g className='labelContainer' transform={`translate(${margins.left}, ${margins.top})`}></g>
                        <g className={`no-data-${name}`} display="none">
                            <text x={`${0}`} y={`${0}`} fill="currentColor" textAnchor="middle" >No hay Autorreportes</text>
                        </g>
                    </svg>
                </div>
            </Card>
        </Card>
        
              
        
    )
  }
  
  export default PieChart;
  