import palette from "../palette";

export default {
  root: {},
  switchHeader: {
    backgroundColor: "white",
  },
  transitionContainer: {
    color: "white",
  },
  iconButton: {
    backgroundColor: "",
    color: "black",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.2)",
      color: "white",
    },
  },
};
