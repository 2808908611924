import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  colors
} from '@material-ui/core';
import { DateTime } from 'luxon';


const useStyles = makeStyles(theme => ({
  root: {},
  consider_margin:{
    margin: theme.spacing(2)
  },
  header: {
    paddingBottom: 0
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  description: {
    padding: theme.spacing(2, 3, 1, 3)
  },
  tags: {
    padding: theme.spacing(0, 3, 1, 3),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  learnMoreButton: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#e7e7e7"
  },
  likedButton: {
    color: colors.red[600]
  },
  shareButton: {
    marginLeft: theme.spacing(1)
  },
  details: {
    padding: theme.spacing(1, 3)
  },
  centrar:{
    margin: 'auto'
  }
}));

const CuestionarioCard = props => {
  const { project, className, ...rest } = props;
  const classes = useStyles();
  var cuest = {}
  const link_to = "/cuestionario/" + project.questionary_id.toString() + "/" + project.id.toString() 
  

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        className={classes.header}
        // disableTypography
        title={
          <Link
            color="textPrimary"
            component={RouterLink}
            to={link_to}
            variant="h5"
          >
            {project.report_name}
          </Link>
        }
        subheader = {DateTime.fromISO(project.creation_date)
                      .setLocale('es-419')
                      .toLocaleString(DateTime.DATETIME_MED)}
      />
       <CardContent className={classes.content}>
        <Divider className={classes.consider_margin} />
        <div className={classes.details}>
          <Grid
            alignItems="center"
            container
            justify="space-between"
            spacing={3}
          >
          </Grid>   
          <Grid
            alignItems="center"
            container
            justify="space-between"
            spacing={3}
          >
             <Grid item className={classes.centrar}>
              <Button
                className={classes.learnMoreButton}
                component={RouterLink}
                size="small"
                to= {link_to}
              >
                Responder Cuestionario
              </Button>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

CuestionarioCard.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired
};

export default CuestionarioCard;
