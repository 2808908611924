import palette from "../palette";

export default {
  root: {},
  toolbarTxt: {
    color: palette.primary.light,
    padding: "0 5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.2)",
    },
  },
  toolbarBtnSelected: {
    color: palette.personal.contrastText,
  },
};
