import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function newAutoreporte() {
    const accessToken = localStorage.getItem('currentToken')
    const response = await fetch(`${URL}/logs/`, {
    
        method: 'POST',
        headers: {
            'Authorization': `Token ${accessToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    
    });
    const res = await response.json();
    if (checkResponse(response)) {
        return  {response, res};
        }
}