import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function readMessage(id, accessToken) {
    const response = await fetch(`${URL}/messages/${id}/read/`, {
        
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        }
    });
    const res = await response.json();
        if (checkResponse(response)) {
            return  res;
            }
    
}