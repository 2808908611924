import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function saveUserCharts(id, charts) {
    const accessToken = await localStorage.getItem('currentToken')
    try {
        const response = await fetch(`${URL}/users/${id}/charts/`, {
        
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Token ${accessToken}`
            },
            body: JSON.stringify({
                charts: charts
            })
        });
        if (checkResponse(response)) {
            return  response;
            }
    } catch (error) {
        console.log(error);
    }
    
}