import { URL } from '../url';
import { checkResponse} from "../checkResponse";

export async function addArticle(article_id, accessToken, user_id) {
    const response = await fetch(`${URL}/users/${user_id}/articles/`, {
        
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        },
        body: JSON.stringify({
            articleID: article_id
        })
    });
    const res = await response.json();
    if (checkResponse(response)) {
        return  res;
        }
    
}