import { URL } from '../url'
import { checkResponse } from "../checkResponse";

export async function addDevice(fbToken, accessToken, user_id) {
    const response = await fetch(`${URL}/users/${user_id}/device/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        },
        body: JSON.stringify({
            token: fbToken,
            type: "web"
        })
    })
    const res = await response.json();
    if (checkResponse(response)) {
        return res;
    }
}
