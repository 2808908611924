import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function editPrescription(prescriptionId, dose, indication, accessToken, userId) {
  const response = await fetch(`${URL}/users/${userId}/prescriptions/`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      prescriptionID: prescriptionId,
      dose,
      indication,
    }),
  });
  const res = await response.json();
  if (checkResponse(res)) {
    return res;
  }
}
