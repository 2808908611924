import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import theme from './theme';
import { configureStore } from './store';
import Routes from './routes';
import {
  ScrollReset,
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './assets/scss/index.scss';

import { getToken, onMessageListener } from './firebase';
import { NotificationsComponent } from './components';


const store = configureStore();
const history = createBrowserHistory();

const App = () => {

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <NotificationsComponent getToken={getToken} onMessageListener={onMessageListener} />
            <ScrollReset />
            <Routes />
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>

  );
};

export default App;
