import firebase from 'firebase/app';
import 'firebase/messaging';
import { addDevice } from 'utils/post';

const firebaseConfig = {
    apiKey: "AIzaSyCMAAsentet7f9FdQXcdJIptCMjZP9T-70",
    authDomain: "aliviauc-20770.firebaseapp.com",
    databaseURL: "https://aliviauc-20770.firebaseio.com",
    projectId: "aliviauc-20770",
    storageBucket: "aliviauc-20770.appspot.com",
    messagingSenderId: "984246697320",
    appId: "1:984246697320:web:e421ca8bbd2c94da4a2add",
    measurementId: "G-X03QJR07RM"
};

// Initialize Firebase
let messaging = null;

if (firebase.messaging.isSupported()) {
  firebase.initializeApp(firebaseConfig);

  // Retrieve firebase messaging
  messaging = firebase.messaging();
}


export const getToken = (accessToken, user_id) => {
  if (messaging) {
    const fbToken = localStorage.getItem("fbToken");
    if (fbToken !== null) {
      return fbToken;
    };
    return messaging.getToken({
        vapidKey:
            'BLPiZsLpxBd6HpGIu0BcI10FhSrfpCrd-xfVASdWq9HHAyDQ37WbTzhrL3-_XyO9DV2bScQP4cnvOiuRh6uoGCk'
    }).then(async (fbToken) => {
        if (fbToken) {
            const res = await addDevice(fbToken, accessToken, user_id);
            localStorage.setItem("fbToken", fbToken)

            return fbToken
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            return null
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
        return null
    });
  };
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });
