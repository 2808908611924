
export const logIn = (accessToken, role) => ({
    type: 'LOG_IN',
    payload: {
      accessToken: accessToken,
      role: role
    }
  });
  
  export const setSession = (accessToken, role) => ({
    type: 'SET_SESSION',
    payload: {
      accessToken: accessToken,
      role: role
    }
  }); 
  
  export const noLogging = () => ({
      type: 'NO_LOGGING',
      payload: null
  })
  
  export const logOut = () => ({
    type: 'LOG_OUT',
    payload: null
  });