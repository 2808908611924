import palette from "../palette"

export default {
    root: {
        '& label.Mui-focused': {
            color: palette.text.primary,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: palette.text.primary,
            },
        },
    },
  };
  