export const setNotifications = (newNotificationsList) => ({
  type: "SET_NOTIFICATIONS",
  payload: [...newNotificationsList],
});

export const setUnreadedNot = (unreaded) => ({
  type: "SET_UNREADED_NOTIFICATIONS",
  payload: unreaded,
});

export const setAllowedNotifications = (allowed) => ({
  type: "SET_ALLOWED",
  payload: allowed,
});
