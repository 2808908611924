import palette from "../palette";

export default {
  root: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.2)",
    },
  },
  yearSelected: {
    backgroundColor: palette.text.secondary,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.3)",
    },
  },
};
