import { URL } from '../url'
import { checkResponse } from "../checkResponse";

export async function alertSystem(doctorID, accessToken) {
  const response = await fetch(`${URL}/users/${doctorID}/patient-recommender/`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Token ${accessToken}`
    },
  });
  if (checkResponse(response)) {
    const res = await response.json();
    return  {res, response};
  }
}
