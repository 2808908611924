import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getPatientsRecommendations(accessToken) {
  try {
    const response = await fetch(`${URL}/alert-classification/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${accessToken}`
      },
    });
    const res = await response.json();
    if (checkResponse(response)) {
      return res;
    }
  } catch (error) {
    alert(error);
    return [];
  }
}
