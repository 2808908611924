import { URL } from '../url'

export async function forgotPassword(user_email) {
    const response = await fetch(`${URL}/users/forgot-password/`, {
        
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

        },
        body: JSON.stringify({
            email: user_email
        })
    });
    
}