import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function createNewCuestionarioDoc(cuestionario) {
    const accessToken = await localStorage.getItem('currentToken')
    const response = await fetch(`${URL}/questionaries/`, {
        
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        },
        body: JSON.stringify(cuestionario)
    
    });
    const res = await response.json();
    if (checkResponse(response)) {
        return  {response, res};
        }
}