import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function addHistoryEvent(title, content, accessToken, user_id) {
  const response = await fetch(`${URL}/users/${user_id}/events/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      title,
      content,
    }),
  });
  const res = await response.json();
  if (checkResponse(response)) {
    return res;
  }
}
