import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getHistoryEventComments(accessToken, eventId) {
  try {
    const response = await fetch(`${URL}/event-patients/${eventId}/comments/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    });
    const res = await response.json();

    if (checkResponse(response)) {
      return res;
    }
  } catch (error) {
    alert(error);
    return [];
  }
}
