import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function postUserMultipleArticles(accessToken ,patientId, articles) {
    const response = await fetch(`${URL}/users/${patientId}/articles/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`,
        },
        body: JSON.stringify({
            'multiple': true,
            'articleID': articles,
        })
    });
    if (checkResponse(response)) {
        return  response;
    }
}
