import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {getAlertsSummary } from 'utils/fetch'
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Card } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    boxShadow: '0 2px 2px 0 #BDC9D7',
  },
  container: {
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  }
}));

const Overview = (props) => {
  const { patientCount,
          className, 
          ...rest } = props;
  const [alarms, setAlarms] = useState({
    'last-week': 0,
    'last-month': 0,
    'today': 0
  });

  const accessToken = localStorage.getItem('currentToken')
  const id = localStorage.getItem('id')
  const classes = useStyles();
  async function fetchData() {
    const response = await getAlertsSummary(accessToken, id);
    setAlarms(response)
    }

  
  useEffect(() => {
    fetchData()
  }, []);
  useEffect(() => {
  }, [alarms, patientCount]);


  return (
    
    <Card className={classes.root}>
      <Grid
        container
        spacing={0}
      >
        <Grid
          className={classes.item}
          item
          md={4}
          sm={4}
          xs={4}
        >
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Alertas últimas 24h
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3">{alarms['today']}</Typography>
            
          </div>
        </Grid>
        <Grid
          className={classes.item}
          item
          md={4}
          sm={4}
          xs={4}
        >
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Alertas semana
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3">{alarms['last-week']}</Typography>
            
          </div>
        </Grid>
        <Grid
          className={classes.item}
          item
          md={4}
          sm={4}
          xs={4}
        >
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Total Pacientes
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3">{patientCount}</Typography>
            
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

Overview.propTypes = {
  className: PropTypes.string
};

export default Overview;
