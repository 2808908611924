const initialState = {
  notificationsList: [],
  unreaded: 0,
  allowed: false,
};

function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_NOTIFICATIONS":
      return {
        ...state,
        notificationsList: action.payload,
      };

    case "SET_UNREADED_NOTIFICATIONS":
      return {
        ...state,
        unreaded: action.payload,
      };

    case "SET_ALLOWED":
      return {
        ...state,
        allowed: action.payload,
      };

    default:
      return state;
  }
}

export default notificationsReducer;
