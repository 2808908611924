import { combineReducers } from 'redux';

//import sessionReducer from './sessionReducer';
import authReducer from './authReducer';
import userDataReducer from './userDataReducer';
import notificationsReducer from './notificationsReducer'
const rootReducer = combineReducers({
  session: authReducer,
  userData: userDataReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
