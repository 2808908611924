import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function actualizarDiagnosisPaciente(input,id) {
    const accessToken = localStorage.getItem('currentToken')
    const output = {"diagnosis": input}
    const response = await fetch(`${URL}/users/${id}/diagnosis/`, {
    
        method: 'PATCH',
        headers: {
            'Authorization': `Token ${accessToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(output)
    
    });
    const res = await response.json();
    if (checkResponse(response)) {
        return  res;
        }
}
