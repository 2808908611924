import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function editTemplate(
  template_name,
  baseQuestions, 
  notBaseQuestions,
  accessToken, 
  eventId
) {
  const questions = [ ...baseQuestions, ...notBaseQuestions ]
  const response = await fetch(`${URL}/templates/${eventId}/`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      'id': eventId,
      template_name,
      questions
    }),
  });
  const res = await response.json();
  if (checkResponse(response)) {
    return { res, response };
  }
}
