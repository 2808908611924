import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function deleteLog( id, accessToken) {
    const response = await fetch(`${URL}/logs/${id}/`, {
        
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        }
    });
    if (checkResponse(response)) {
        return  response;
        }
    
}