import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Button, Hidden } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  summaryButton: {
    backgroundColor: theme.palette.white,
    marginRight: theme.spacing(1)
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  image: {
    width: '100%',
    maxHeight: 400
  }
}));

const Header = props => {
  const { className, ...rest } = props;
  const id = localStorage.getItem('id')
  const classes = useStyles();
  const session = useSelector(state => state.session);
  const userData = useSelector(state => state.userData.serverUserData);
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="center"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Inicio
          </Typography>
          <Typography
            component="h1"
            gutterBottom
            variant="h3"
          >
            Buenos días {userData.first_name}
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle1"
          >
            Veamos como vas hoy
          </Typography>
          <Button
            className={classes.summaryButton}
            edge="start"
            variant="contained"
            component={RouterLink}
            to={`/profile/${id}/timeline`}
          >
            <AssignmentIcon className={classes.icon} />
            Mi Diagnóstico
          </Button>
          <Button
            className={classes.summaryButton}
            edge="start"
            variant="contained"
            component={RouterLink}
            to={`/profile/${id}/dashboard`}
          >
            <BarChartIcon className={classes.icon} />
            Ver Estadísticas
          </Button>
        </Grid>
        <Hidden smDown>
          <Grid
            item
            md={6}
          >
            <img
              alt="Cover"
              className={classes.image}
              src="/images/undraw_growth_analytics_8btt.svg"
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
