const initialState={ 
    serverUserData: {
            avatar: "",
            created_at: "",
            email: "",
            first_name: "",
            last_name: "",
            role: "",
            answered_today: false,
            profile: {
                specialty: "",
                rut: '',
                cellphone_num: '',
                nick: '',
                address: '',
                dob: '',
                photo: '',
                unread_messages: 0,
                unread_alerts: 0,
                stars: 0,
                stars_date: null,
            }
      }
  }
  
  
  function userData(state = initialState, action) {
    switch (action.type) {
  
      case 'SERVER_USER_DATA':
        return {  
            ...state,
            serverUserData: action.payload
              };
  
      case 'NEW_BASIC_USER_DATA':
        return {  
            ...state,
            newBasicUserData: action.payload
              };
  
    //   case 'NEW_SOCIAL_USER_DATA':
    //     return {  
    //         ...state,
    //         newSocialUserData: action.payload
    //           };
  
    //   case 'NEW_BANK_USER_DATA':
    //     return {  
    //         ...state,
    //         newBankUserData: action.payload
    //           };
  
    //   case 'NEW_SETTINGS_USER_DATA':
    //     return {  
    //         ...state,
    //         newSettingsUserData: action.payload
    //           };
  
  
    //   case 'NEW_PYME_LOGO':
    //     return {  
    //         ...state,
    //         newPymeLogo: action.payload
    //           };
  
    //   case 'NEW_PROFILE_PHOTO':
    //     return {  
    //         ...state,
    //         newProfileLogo: action.payload
    //           };
  
      default:
        return state;
    }
  }
  
  
  export default userData