const initialState = {
    currentUserRole: null,
    currentToken: null,
    loggingIn: null
}

function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOG_IN':
      return { 
        currentToken: action.payload.accessToken, 
        currentUserRole: action.payload.role,
        loggingIn: true 
      };
    case 'SET_SESSION':
      return {
        currentToken: action.payload.accessToken, 
        currentUserRole: action.payload.role,
        loggingIn: true
      }
    case 'NO_LOGGING':
      return {
        ...state,
        loggingIn: true
      };
    
    case 'LOG_OUT':
      return { 
        currentToken: null, 
        currentUserRole: null, 
        loggingIn: false 
      };
    
    default:
      return state;
  }
}

export default authReducer