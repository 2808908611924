import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function deleteQuestionary( id, accessToken) {
    const response = await fetch(`${URL}/questionaries/${id}/`, {
        
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        }
    });
    const res = await response;
    if (checkResponse(res)) {
        return  res;
        }
    
}