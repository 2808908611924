import { URL } from '../url';
import { checkResponse} from "../checkResponse";

export async function getPatients(accessToken, id) {
    try {
        const response = await fetch(`${URL}/users/${id}/patients/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });
        const res = await response.json();
        if (checkResponse(response)) {
            return  res;
            }
    } catch (error) {
        alert(error)
        return null;
    }
}