/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { 
  Typography, 
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  ListItemText,
  
} from '@material-ui/core';
import { default as Overview } from './Overview'
import { getChartsMetadata } from 'utils/fetch';
import { saveUserCharts } from 'utils/post';


const useStyles = makeStyles(theme => ({
  root: {},
  leftGrid: {
    margin: 'auto',
  },
  formControl: {
    minWidth: 120,
    margin: theme.spacing(1),
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
}));

const Header = (props) => {

  const { timeRange,
          setTimeRange,
          selectedCharts,
          setSelectedCharts,
          patientCount,
          resetSelection,
          ...rest } = props;

  const classes = useStyles();

  const id = localStorage.getItem('id');
  const [ chartsMetadata, setChartsMetadata] = useState([]);

  async function handleTimeChange(event){
    setTimeRange(event.target.value);
  }

  async function chartSelectionChange(event){
    const selectedChartsData = chartsMetadata.filter((obj) => event.target.value.indexOf(obj.stat) > -1);
    const chartIds = selectedChartsData.map((obj) => obj.id);
    await saveUserCharts(id, chartIds);
    setSelectedCharts(selectedChartsData);
  }
  
  async function fetchMetadata() {
    const { res } = await getChartsMetadata();
    setChartsMetadata(res)
  }

  useEffect(() => {
    fetchMetadata()
  }, [])

  return (
    <div
      {...rest}
      className={classes.root}
    >
      <Grid
        container
        justify="space-between"
        spacing={3}
      >
        <Grid
          item
          lg={7}
          xs={12}
          className={classes.leftGrid}
        >
          <Typography
            component="h1"
            gutterBottom
            variant="h3"
          >
            Estado General de mis Pacientes
          </Typography>
          <Box display="flex" flexDirection="row">
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Período de Tiempo</InputLabel>
              <Select
                value={timeRange}
                onChange={handleTimeChange}
              >
                <MenuItem value={'today'}>Últimas 24 hrs</MenuItem>
                <MenuItem value={'last-week'}>Última semana</MenuItem>
                <MenuItem value={'last-month'}>Último mes</MenuItem>
                <MenuItem value={'last3'}>Últimos 3 meses</MenuItem>
                <MenuItem value={'all'}>Todo el tiempo</MenuItem>

              </Select>

            </FormControl>  
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id="demo-mutiple-name-label">Seleccionar Gráficos</InputLabel>
              <Select
                multiple
                value={selectedCharts.map((d) => d.stat)}
                onChange={chartSelectionChange}
                renderValue={() => `${selectedCharts.length} selecionados`}
              >
                {
                  chartsMetadata.map((chart) => {
                    return <MenuItem key={chart.id} name={chart.id} value={chart.stat}>
                            <Checkbox checked={selectedCharts.map((d) => d.stat).indexOf(chart.stat) > -1} />
                            <ListItemText primary={chart.title} />
                          </MenuItem>
                  })
                }

              </Select>

            </FormControl>         
          </Box>
        </Grid>
        <Grid
          item
          lg={5}
          xs={12}
        >
          <Overview patientCount={patientCount}/>
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

Header.defaultProps = {};

export default Header;
