import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function deleteHistoryEventComment(eventId, commentId, accessToken) {
  const response = await fetch(`${URL}/event-patients/${eventId}/comments/`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      commentID: commentId,
    }),
  });
  const res = await response;
  if (checkResponse(response)) {
    return res;
  }
}
