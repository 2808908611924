import { RECOMENDATIONS_URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function executeRecomendations(tagsId) {
  const response = await fetch(`${RECOMENDATIONS_URL}/article_recommender/execute/`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'tagsId': tagsId
    })
  });
  if (checkResponse(response)) {
    const res = await response.json();
    res.recommendations = JSON.parse(res.recommendations);
    return  {res, response};
  }
}
