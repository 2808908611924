import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function addHistoryEventComment(content, accessToken, eventId) {
  const response = await fetch(`${URL}/event-patients/${eventId}/comments/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      comment: content,
    }),
  });
  const res = await response.json();
  if (checkResponse(response)) {
    return res;
  }
}
