import React, { useRef,useState, Fragment, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Drawer, colors, Divider, Paper, Avatar, Typography } from '@material-ui/core';
import { Tooltip,Hidden } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    padding: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  },
  stars:{
    display: 'flex'
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: "#802e87",
    '&:hover': {
      backgroundColor: colors.blue[900]
    }
  },
  enabledStar: {
    color: theme.palette.personal.main,
  },
  disabledStar: {
    color: "#c9c3c9",
  },
}));


const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;
  const classes = useStyles();
  const router = useRouter();
  const role = localStorage.getItem('role');
  const userData = useSelector(state => state.userData.serverUserData);
  const moreRef = useRef(null);
  const maxStars = 2;
  const validStars = userData.profile.stars;
  const myStars = [...Array(validStars).keys()];
  const difStars = [...Array(Math.max(maxStars - validStars, 0)).keys()];

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

  }, [router.location.pathname]);

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={userData.avatar}
          to= {'/account'}
        />
        <Typography
          className={classes.name}
          variant="h4"
        >
          {userData.first_name} {userData.last_name}
        </Typography>
        {role!='patient'? (role=='doctor' ? 
        <Typography variant="body2">{userData.profile.specialty}</Typography>
        : <Typography variant="body2">Administrador</Typography>):
        <Tooltip title="Estas son las estrellas que has conseguido,puedes obtener hasta 2 estrellas diarias, una por contestar el autorreporte y otra por completar tus tareas"> 
        <div className={classes.stars}> 
        <Typography variant="h4">{userData.profile.stars}</Typography> 
        {myStars.map(() => {return <StarIcon className={classes.enabledStar} />})}
        {difStars.map(() => {return <StarIcon className={classes.disabledStar} />})}
       </div>
       </Tooltip>
        }
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig.map(list => {if ( list.role.includes(role)) {return (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        )}})}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div
            {...rest}
            className={clsx(classes.root, className)}
          >
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
