import { URL } from "../url";
import moment from 'moment';

export async function getCSV(accessToken, params, filename) {
  try {
    let query = null;
    if (params) {
      const query_params = new URLSearchParams(params);
      query = query_params.toString();
    }
    const response = await fetch(`${URL}/users/export-stats-overview/${query ? `?${query}` : ""}`, {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${accessToken}`,
      },
    });

    const res = await response.blob();
    const url = window.URL.createObjectURL(res);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    const date = moment().format('DD-MM-YYYY-HH-mmA');
    const name = filename + "-" + date + ".csv";
    a.download = name; //change name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

  } catch (error) {
    alert(error);
    return [];
  }
}
