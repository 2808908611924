import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function createUser(data) {
    const response = await fetch(`${URL}/register/`, {
        
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            password1: data.password1,
            password2: data.password2,
            token: data.token,
            profile: data.profile
        })
    });
    const res = await response.json();
    if (checkResponse(response)) {
        return  {response, res};
        }
}