import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { createArticleId } from 'utils/post';
import { URL } from 'utils/url';
import {
  Button,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  colors
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import bytesToSize from 'utils/bytesToSize';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  trialButton: {
   // marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: "#802e87",
    '&:hover': {
      backgroundColor: colors.blue[900]
    }
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 130
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const FilesDropzone = props => {
  const { className, id,token, setSuccess, setCargando, setAparece, setLoading, setUpdate, ...rest } = props;
  const [newFileData, setNewFileData] = useState({});
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  //const fileRef = useRef(null)

  const handleDrop = useCallback(acceptedFiles => {
    setFiles(files => [...files].concat(acceptedFiles));
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
  };
  const uploadFile = () => {
    if (newFileData.article_name && newFileData.text) {
    const data = new FormData()
    data.append('file', files[0]);
    try{
      createArticleId(newFileData.article_name,newFileData.text, token ).then(res => {
        handleRemoveAll()
        setAparece(true)
        setSuccess(false)
    setCargando(true)
        fetch(`${URL}/articles/${res.id}/document/`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`
        },
          body: data
        }).then((resp) => {
          setSuccess(true)
          setCargando(false)
          setUpdate(true)
          
        })
      })
    } catch (error) {
      alert('Hubo un error');
  }
  }else{
    alert('Falta completar uno de los campos: Nombre o descripción')
  }
     
  }
  const handleChange = (event) => {
    setNewFileData({
        ...newFileData,
        [event.target.name]: event.target.value,
    });
};

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false
  });

  return (
    
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt="Selecciona un archivo"
            className={classes.image}
            src="/images/undraw_add_file2_gvbb.svg"
          />
        </div>
          <div>
            <Typography
              gutterBottom
              variant="h3"
            >
              Seleccionar Archivo
            </Typography>
            <Typography
              className={classes.info}
              color="textSecondary"
              variant="body1"
            >
              Arrastra el archivo hasta aquí 
            </Typography>
          </div>
        </div>
          {files.length > 0 && (
                <Fragment>
                  <PerfectScrollbar options={{ suppressScrollX: true }}>
                    <List className={classes.list}>
                      {files.map((file, i) => (
                        <ListItem
                          divider={i < files.length - 1}
                          key={uuid()}
                        >
                          <ListItemIcon>
                            <FileCopyIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={file.name}
                            primaryTypographyProps={{ variant: 'h5' }}
                            secondary={bytesToSize(file.size)}
                          />
                          
                        </ListItem>
                      ))}
                    </List>
                    <Typography spacing={1} >
                                 El tamaño máximo permitido por archivo es de 250 MB. La carga del archivo puede tomar unos momentos.
                                </Typography>
                    <form autoComplete='off' noValidate>
                          <Grid item lg={6} md={6} xl={6} xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        placeholder='Escriba aquí..'
                                        helperText='Escriba el nombre del documento'
                                        label='Nombre'
                                        margin='normal'
                                        name='article_name'
                                        onChange={handleChange}
                                        value={newFileData.name}
                                        variant='outlined'
                                        color='secondary'
                                        
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} xl={6} xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        placeholder='Escriba aquí..'
                                        helperText='Escriba descripcioón'
                                        label='Descripción'
                                        margin='normal'
                                        name='text'
                                        onChange={handleChange}
                                        value={newFileData.description}
                                        variant='outlined'
                                        color='secondary'
                                        
                                    />
                              </Grid>
                            </form>
                  </PerfectScrollbar>
                  <div className={classes.actions}>
                    <Button

                      onClick={handleRemoveAll}
                      size="small"
                    >
                      Descartar
                    </Button>
                    <Button
                      className={classes.trialButton}
                      // color="secondary"
                      size="small"
                      variant="contained"
                      onClick={uploadFile}
                    >
                      Subir
                    </Button>
                  </div>
                </Fragment>
              )}
         </div>
  );
};

FilesDropzone.propTypes = {
  className: PropTypes.string
};

export default FilesDropzone;


// {(!state) ? 