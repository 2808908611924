/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import OverviewView from './views/Overview';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { logIn, setUserData } from './actions';
import {  getCurrentUser } from './utils/fetch';

const Routes = () => {
  const loggingIn = useSelector(state => state.session.loggingIn)
  const [aparecen, setAparecen] = React.useState(false);
  const [cargandon, setCargandon] = React.useState(true);
  const [successn, setSuccessn] = React.useState(false);
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('currentToken');
  const id = localStorage.getItem('id');
  const dispatch = useDispatch();
  const docoptions = [
    {
      path: '/',
      exact: true,
      loggedIn: loggingIn,
      component: () =>  <Redirect to = "/dashboards/analytics"/>
    },
    {
      path: '/auth',
      component: AuthLayout,
      loggedIn: loggingIn,
      routes: [
        {
          path: '/auth/login',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Login'))
        },
        {
          path: '/auth/register/:token',
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import('views/Register'))
        },
        {
          path: '/auth/autentificar',
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import('views/Authenticate'))
        },
        {
          path: '/auth/password',
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import('views/Password'))
        },
        {
          component: () => <Redirect to="/dashboards/analytics" />
        }
      ]
    },
    {
      path: '/errors',
      component: ErrorLayout,
      routes: [
        {
          path: '/errors/error-401',
          exact: true,
          component: lazy(() => import('views/Error401'))
        },
        {
          path: '/errors/error-404',
          exact: true,
          component: lazy(() => import('views/Error404'))
        },
        {
          path: '/errors/error-500',
          exact: true,
          component: lazy(() => import('views/Error500'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      route: '*',
      component: DashboardLayout,
      loggedIn: loggingIn,
      aparecen: aparecen,
      cargandon: cargandon,
      successn: successn,
      setAparecen: setAparecen,
      setCargandon: setCargandon,
      setSuccessn: setSuccessn,
      routes: [
        {
          path: '/mantenedor/recomendaciones',
          exact: true,
          setAparecen: setAparecen,
          loggedIn: loggingIn,
          component: lazy(() => import('views/RecommendationsMantainer'))
        },
        {
          path: '/archivos',
          exact: true,
          setAparecen: setAparecen,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Articles'))
        },
        {
          path: '/archivos/:tab',
          exact: true,
          setAparecen: setAparecen,
          setCargandon: setCargandon,
          setSuccessn: setSuccessn,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Articles'))
        },
        {
          path: '/password',
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import('views/Password'))
        },
        {
          path: '/dashboards/analytics',
          exact: true,
          loggedIn: loggingIn,
          component: DashboardAnalyticsView
        },
        {
          path: '/mail',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Mail'))
        },
        {
          path: '/specialties',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Specialties'))
        },
        {
          path: '/management/customers',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/CustomerManagementList'))
        },
        {
          path: '/profile/:id',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Profile'))
        },
        {
          path: '/diagnose',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Profile'))
        },
        {
          path: '/profile/:id/:tab',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Profile'))
        },
        {
          path: '/new-paciente',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/newPaciente'))
        },
        {
          path: '/new-tratante',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/newTratante'))
        },
        {
          path: '/autoreporte',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Chatbot'))
        },
        {
          path: '/cuestionario/:id_preguntas/:id_reporte',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Cuestionario'))
        },
        {
          path: '/asignar-template/:id_template',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/AsignarTagTemplate'))
        },
        {
          path: '/templates',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/TemplatesDoctor'))
        },
        {
          path: '/template/modify/:id',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/ModifyTemplate'))
        },
        {
          path: '/template/:id',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Template'))
        },
        {
          path: '/lista-tags',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Tags'))
        },
        {
          path: '/ver-report/:id/:id_paciente',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/VerCuestionario'))
        },
        {
          path: '/ver-log/:id/:id_paciente',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/VerAutoreporte'))
        },
        {
          path: '/new-cuestionario',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/newCuestionario'))
        },
        {
          path: '/new-template',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/newTemplate'))
        },
        {
          path: '/cuestionariosdoctor',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/CuestionariosDoctor'))
        },
        {
          path: '/tags/:id',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/SelectTags'))
        },
        {
          path: '/reports/:id',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/SelectCuestionario'))

        },
        {
          path: '/getting-started',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/GettingStarted'))
        },
        {
          path: '/account',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Account'))
        },
        {
          component: () => <Redirect to="/dashboards/analytics" />
        }
      ]
    }
  ];
  const options = [
    {
      path: '/',
      exact: true,
      loggedIn: loggingIn,
      component: () =>  <Redirect to = "/overview"/>
    },
    {
      path: '/auth',
      component: AuthLayout,
      loggedIn: loggingIn,
      routes: [
        {
          path: '/auth/login',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Login'))
        },
        {
          path: '/auth/register/:token',
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import('views/Register'))
        },
        {
          path: '/auth/autentificar',
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import('views/Authenticate'))
        },
        {
          path: '/auth/password',
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import('views/Password'))

        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      path: '/errors',
      component: ErrorLayout,
      routes: [
        {
          path: '/errors/error-401',
          exact: true,
          component: lazy(() => import('views/Error401'))
        },
        {
          path: '/errors/error-404',
          exact: true,
          component: lazy(() => import('views/Error404'))
        },
        {
          path: '/errors/error-500',
          exact: true,
          component: lazy(() => import('views/Error500'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      route: '*',
      component: DashboardLayout,
      loggedIn: loggingIn,
      routes: [
        {
          path: '/archivos',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Articles'))
        },
        {
          path: '/password',
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import('views/Password'))
        },
        {
          path: '/mail',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Mail'))
        },
        {
          path: '/archivos/:tab',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Articles'))
        },
        {
          path: '/overview',
          exact: true,
          loggedIn: loggingIn,
          component: OverviewView
        },
        {
          path: '/profile/:id',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Profile'))
        },
        {
          path: '/diagnose',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Profile'))
        },
        {
          path: '/profile/:id/:tab',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Profile'))
        },
        {
          path: '/autoreporte',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Chatbot'))
        },
        {
          path: '/cuestionario/:id_preguntas/:id_reporte',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Cuestionario'))
        },
        {
          path: '/asignar-template/:id_template',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/AsignarTagTemplate'))
        },
        {
          path: '/templates',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/TemplatesDoctor'))
        },
        {
          path: '/template/:id',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Template'))
        },
        {
          path: '/lista-tags',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Tags'))
        },
        {
          path: '/ver-report/:id/:id_paciente',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/VerCuestionario'))
        },
        {
          path: '/ver-log/:id/:id_paciente',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/VerAutoreporte'))
        },
        {
          path: '/new-cuestionario',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/newCuestionario'))
        },
        {
          path: '/new-template',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/newTemplate'))
        },
        {
          path: '/cuestionariosdoctor',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/CuestionariosDoctor'))
        },
        {
          path: '/tags/:id',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/SelectTags'))
        },
        {
          path: '/reports/:id',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/SelectCuestionario'))

        },
        {
          path: '/cuestionarios',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/ProjectList'))
        },
        {
          path: '/getting-started',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/GettingStarted'))
        },
        {
          path: '/account',
          exact: true,
          loggedIn: loggingIn,
          component: lazy(() => import('views/Account'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    }
  ];
  const login = [
    
    {
      path: '*',
      component: AuthLayout,
      loggedIn: loggingIn,
      routes: [
        {
          path: '/auth/register/:token',
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import('views/Register'))
        },
        {
          path: '/auth/autentificar',
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import('views/Authenticate'))
        },
        {
          path: '/auth/password',
          loggedIn: loggingIn,
          exact: true,
          component: lazy(() => import('views/Password'))

        },
        {
          loggedIn: loggingIn,
          component: lazy(() => import('views/Login'))
        }
      ]
    }
  ]
  if (loggingIn) {
    if (role=='patient') {
      return (
        renderRoutes(options))
    } else {
      return (
        renderRoutes(docoptions))
    };
  } else { 
    if (token) {
      dispatch(logIn(token, role))
      getCurrentUser(token, id).then(user => {
        dispatch(setUserData(user)) 
      })
      
      if (role=='patient') {
        return (
          renderRoutes(options))
      } else {
        return (
          renderRoutes(docoptions))
      };
    } else{
      return (renderRoutes(login))
  }}
}


export default Routes;

