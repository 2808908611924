import useRouter from 'utils/useRouter';
import { useDispatch } from 'react-redux';

export function checkResponse(response) {
    if (response.status==401) {
        alert("Su sesión ha expirado")
        localStorage.clear();
        window.location.replace('/auth/login')
    } else { return true}
  }
  
  export default checkResponse