import { URL } from '../url';
import { checkResponse} from "../checkResponse";

export async function getStatsOverview(accessToken, time) {
    try {
        const response = await fetch(`${URL}/users/stats-overview/?range=${time}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Token ${accessToken}`
            },
        });
        const res = await response.json();
        if (checkResponse(response)) {
            return  res;
            }
    } catch (error) {
        alert(error)
        return null;
    }
}