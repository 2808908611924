import { URL } from '../url';
import { checkResponse} from "../checkResponse";
export async function getCurrentUser(accessToken, id) {
    try {
        
        const response = await fetch(`${URL}/users/${id}/`, {
            method: 'GET',
            headers: {
                'Authorization': `Token ${accessToken}`
            }
        });

        const res = await response.json();
        if (checkResponse(response)){
        const res2 = {
            id: id,
            first_name: res.first_name, //res.email,
            base_pain: res.profile.base_pain,
            last_name: res.last_name,
            email: res.email, //res.email,
            avatar: res.profile.image,
            tags: res.profile.tags,
            nick: res.profile.nick,
            rut: res.profile.rut,
            answered_today: res.profile.answered_today,
            address: res.profile.address,
            cellphone_num: res.profile.cellphone_num,
            specialists: res.profile.current_discipline,
            periodicity: res.profile.periodicity,
            diagnosis: res.profile.diagnosis,
            dob: res.profile.dob,
            profile: {
                specialty: res.profile.specialty_name,
                unread_messages: res.profile.unread_messages,
                unread_alerts: res.profile.unread_alerts,
                stars: res.profile.stars,
                stars_date: res.profile.stars_date
            }
          }
        return res2;}
    } catch (error) {
        console.log(error)
        alert(error)
        window.location.replace('auth/login');
        return null;
    }
}