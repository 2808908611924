import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function editHistoryEventComment(content, accessToken, eventId, commentId) {
  const response = await fetch(`${URL}/event-patients/${eventId}/comments/`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      commentID: commentId,
      comment: content,
    }),
  });
  const res = await response.json();
  if (checkResponse(res)) {
    return res;
  }
}
