/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setNewBasicUserData,setUserData } from './../../../../actions'
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  CircularProgress,
  ListItemText,
  Radio,
  Tooltip,
  TextField,
  Fab,
  Typography,
  colors
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';

import axios from 'utils/axios';
import { Label } from 'components';

import { getTareas, getCurrentUser } from 'utils/fetch';
import { actualizarTareas } from 'utils/patch';

const GrayCheckbox = withStyles({
  root: {
    color: green,
    '&$checked': {
      color: green,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  opcion_espeficica: {
    marginLeft: theme.spacing(2.7),
    marginTop: theme.spacing(0.5)
  },
  addIcon: {
    marginRight: theme.spacing(1)
  },
  eliminar_opcion: {
    marginLeft: theme.spacing(2)
  },
  contenedor_martin: {
    display: 'inline-flex',
    width: theme.spacing(100),
    marginLeft: theme.spacing(1)
  },
  textoNuevaOpcion: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1.5)
  },
  optionRadio: {
    marginTop: theme.spacing(1.3),
    marginLeft: theme.spacing(0.5)
  },
  done: {
    color: "#bdbdbd"
  }
}));

const Todos = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [todos, setTodos] = useState([]);
  const [nuevaTarea, setNuevaTarea] = useState("");
  const [output, setOutput] = useState([])
  const [bool, setBool] = useState(false)
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const previusData = useSelector((state) => state.userData.serverUserData);
  const accessToken = localStorage.getItem('currentToken')
  const id =  localStorage.getItem('id').toString()

  async function fetchData() {
    const {res, response} = await getTareas();
    setTodos(res.tasks)
  }

  async function updateUserData(output) {
    let tasks_completed= true
    if(output.length == 0 ){
      tasks_completed = false
    }
    output.map((todo)=>(
      !todo.done?(tasks_completed= false):(null)
    ))
    if (tasks_completed) {
      const newUserData = await getCurrentUser(accessToken, id);
      dispatch(setUserData(newUserData));
    }
  }

  useEffect(() => {
    let mounted = true;
    fetchData()
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    bool ? (
      actualizarTareas(todos)
    ) : (
      setBool(true)
    )
    updateUserData(todos); 
  }, [output]);

  const escribirOpcion = (valor) => {
    setNuevaTarea(valor);
  }

  const handleChange = (event, todo) => {
    event.persist();
    setOutput(output =>
      output.map(t => {
        if (t.position === todo.position) {
          return { ...todo, done: !todo.done };
        }
        return t;
      })
    );
    setTodos(todos =>
      todos.map(t => {
        if (t.position === todo.position) {
          return { ...todo, done: !todo.done };
        }
        return t;
      })
    );
  };

  const handleAgregarTarea = () => {
    let r = todos.length
    setOutput([...todos, {position: r + 1, title: nuevaTarea, done: false}])
    setTodos([...todos, {position: r + 1, title: nuevaTarea, done: false}]);
    setNuevaTarea("");
  }

  const handleEliminarOpcion = (numero) => {
    let aux = [];
    todos.map((todo) => {
      todo.position === numero + 1 ? (
        console.log("Eliminando")
      ) : (
        todo.position > numero ? (
          aux.push({position: todo.position - 1, title: todo.title, done: todo.done})
        ) : (
          aux.push(todo)
        )
      )
    })
    setTodos(aux)
    setOutput(aux)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(!loading) ? 
      <>
      <CardHeader
        action={
          <Button
            color="primary"
            size="small"
          >
          <AddIcon className={classes.addIcon} />
            Add
          </Button>
        }
        title="Mis Tareas"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {todos.map((todo, i) => (
            <ListItem
              divider={i < todos.length - 1}
              key={todo.position}
            >
              <FormControlLabel className={clsx({[classes.done]: todo.done})}
                control={<GrayCheckbox checked={todo.done} onChange={event => handleChange(event, todo)} />}
                label={<Typography className={clsx({[classes.done]: todo.done})}variant="body1">
                        {todo.title}
                      </Typography>}
              ></FormControlLabel>
              <Tooltip title="Eliminar Tarea">
                <Fab style={{ marginLeft: "auto" }}  size="small" color="grey" aria-label="delete" onClick={(event) => handleEliminarOpcion(i)}> 
                  <DeleteIcon fontSize="medium" />
                </Fab>
              </Tooltip>
              {/* <ListItemIcon>
                <Radio
                  checked={todo.done}
                  onClick={event => handleChange(event, todo)}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  className={clsx({
                    [classes.done]: todo.done
                  })}
                  variant="body1"
                >
                  {todo.title}
                </Typography>
              </ListItemText>
              {getLabel(todo)}
              <Tooltip title="Archive">
                <IconButton>
                  <ArchiveIcon />
                </IconButton>
              </Tooltip> */}
            </ListItem>
          ))}
        </List>
        <Divider />
        <div className={classes.contenedor_martin}>
          <Fab size="small" color="grey" aria-label="add" className={classes.optionRadio} onClick={handleAgregarTarea}> 
            <AddIcon/>
          </Fab>
          <TextField
            fullWidth="true"
            className={classes.opcion_espeficica}
            value={nuevaTarea}
            multiline
            label="Nueva Tarea"
            onChange={(event) => escribirOpcion(event.target.value)} 
          />
        </div>
      </CardContent>
      </>
      :
      <div className={classes.loader}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <CircularProgress style={{'color': '#802e87'}}/>
        </Grid>

      </div>}
    </Card>
    
  );
};

Todos.propTypes = {
  className: PropTypes.string
};

export default Todos;
