import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function actualizarHorarioPaciente(date) {
    const accessToken = localStorage.getItem('currentToken')
    const id = await localStorage.getItem('id').toString()
    const output = {"profile":{"periodicity": date}};
   
    const response = await fetch(`${URL}/users/${id}/`, {
    
        method: 'PATCH',
        headers: {
            'Authorization': `Token ${accessToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(output)
    
    });
    const res = await response.json();
    if (checkResponse(res)) {
        return  {response, res};
        }
}