import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function editPatientsRecommendations(accessToken, recommendations) {
  const response = await fetch(`${URL}/alert-classification/`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify(recommendations),
  });
  const res = await response.json();
  if (checkResponse(res)) {
    return res;
  }
}
