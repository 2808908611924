/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MailIcon from '@material-ui/icons/MailOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import FavoriteIcon from '@material-ui/icons/Favorite';


export default [
  {
    title: 'Tratante',
    role: ['doctor'],
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboards/analytics',
        icon: DashboardIcon,
      },
      {
        title: 'Mis Pacientes',
        href: '/management/customers',
        icon: PeopleIcon,
      },
      {
        title: 'Archivos',
        href: '/archivos/results',
        icon: FolderIcon,
      },
      {
        title: 'Cuestionarios',
        href: '/cuestionariosdoctor',
        icon: ListAltIcon,
      },
      {
        title: 'Avisos',
        href: '/mail',
        icon: MailIcon,
      },
      {
        title: 'Etiquetas de Pacientes',
        href: '/lista-tags',
        icon: LocalOfferIcon 
      },
      {
        title: 'Plantilla de Preguntas',
        href: '/templates',
        icon: AddToPhotosIcon 
      },
      {
        title: 'Mi Cuenta',
        href: '/account',
        icon: AccountBoxIcon,
      },
      {
        title: 'Registrar Paciente',
        icon: PersonAddIcon,
        href: '/new-paciente'
          
      }
    ]
  },
  {
    title: 'Administrador',
    role: ['admin'],
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboards/analytics',
        icon: DashboardIcon,
      },
      {
        title: 'Mis Pacientes',
        href: '/management/customers',
        icon: PeopleIcon,
      },
      {
        title: 'Archivos',
        href: '/archivos/results',
        icon: FolderIcon,
      },
      {
        title: 'Cuestionarios',
        href: '/cuestionariosdoctor',
        icon: ListAltIcon,
      },
      {
        title: 'Avisos',
        href: '/mail',
        icon: MailIcon,
      },
      {
        title: 'Etiquetas de Pacientes',
        href: '/lista-tags',
        icon: LocalOfferIcon 
      },
      {
        title: 'Plantilla de Preguntas',
        href: '/templates',
        icon: AddToPhotosIcon 
      },
      {
        title: 'Recomendaciones',
        href: '/mantenedor/recomendaciones',
        icon: FavoriteIcon 
      },
      {
        title: 'Especialidades',
        href: '/specialties',
        icon: GroupAddIcon 
      },
      {
        title: 'Mi Cuenta',
        href: '/account',
        icon: AccountBoxIcon,
      },
      {
        title: 'Nuevo Usuario',
        icon: PersonAddIcon,
        children: [
          {
            title: 'Registrar Tratante',
            href: '/new-tratante'
          },
          {
            title: 'Registrar Paciente',
            href: '/new-paciente'
          }
        ]
      }
    ]
  },
  {
    title: 'Paciente',
    role: ['patient'],
    pages: [
      {
        title: 'Inicio',
        href: '/overview',
        icon: HomeIcon
      },
      {
        title: 'Autoreporte',
        href: '/autoreporte',
        icon: ListAltIcon,
      },
      {
        title: 'Mi Diagnóstico',
        href: '/diagnose',
        icon: AssignmentIcon,
      },
      {
        title: 'Archivos',
        href: '/archivos/results',
        icon: FolderIcon,
      },
      {
        title: 'Cuestionarios',
        href: '/cuestionarios',
        icon: ListAltIcon,
      },
      {
        title: 'Avisos',
        href: '/mail',
        icon: MailIcon,
      },
      {
        title: 'Mi Cuenta',
        href: '/account',
        icon: AccountBoxIcon,
      },
    ]
  }
];
