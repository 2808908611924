import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Snackbar } from "@material-ui/core";
import {
  setNotifications,
  setUnreadedNot,
  setAllowedNotifications,
} from "../../actions";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  notificationAlert: {
    backgroundColor: theme.palette.personal.main,
    color: "rgba(255,255,255,0.8)",
  },
  titleAlert: {
    color: theme.palette.personal.contrastText,
  },
  badgeNotifications: {
    color: theme.palette.personal.main,
  },
}));

const NotificationsComponent = (props) => {
  const { getToken, onMessageListener, ...rest } = props;
  const classes = useStyles();
  const notifications = useSelector(
    (state) => state.notifications.notificationsList
  );
  const dispatch = useDispatch();
  
  const user_id = localStorage.getItem("id");
  const accessToken = localStorage.getItem("currentToken");

  const [currentNotifications, setCurrentNotifications] = useState([]);
  
  useEffect(() => {
    const init = async () => {
      if (user_id && accessToken) {
        const token = await getToken(accessToken, user_id);
        if (token !== null && token !== undefined) {
          dispatch(setAllowedNotifications(true));
          onMessageListener()
            .then((payload) => {
              setCurrentNotifications([
                {
                  title: payload.notification.title,
                  body: payload.notification.body,
                  new: true,
                },
                ...currentNotifications,
              ]);
            })
            .catch((err) => console.log("failed: ", err));
        }
      }
    };
    init();
  }, [notifications]);

  const [newNotification, setNewNotification] = useState({
    title: "Titulo de la notificacion",
    body: "Mensaje a mostrar en la casilla.",
  });
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [unreadedNotifications, setUnreadedNotifications] = useState(0);

  const calcUnreadedNot = () => {
    const unreaded = currentNotifications.filter((n) => n.new);
    setUnreadedNotifications(unreaded.length);
    dispatch(setUnreadedNot(unreaded.length));
  };

  useEffect(() => {
    if (currentNotifications.length > 0) {
      setNotificationOpen(false);
      const noti = currentNotifications[0];
      setNewNotification(noti);
      calcUnreadedNot();
      setNotificationOpen(true);
      dispatch(setNotifications([...currentNotifications]));
    }
  }, [currentNotifications]);

  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    } else if (reason !== "timeout") {
      newNotification.new = false;
      calcUnreadedNot();
    }

    setNotificationOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={notificationOpen}
      autoHideDuration={6000}
      onClose={handleNotificationClose}
    >
      <Alert
        onClose={handleNotificationClose}
        severity="info"
        variant="filled"
        className={classes.notificationAlert}
      >
        <AlertTitle className={classes.titleAlert}>
          {newNotification.title}
        </AlertTitle>
        {newNotification.body}
      </Alert>
    </Snackbar>
  );
};

NotificationsComponent.propTypes = {
  className: PropTypes.string,
};

export default NotificationsComponent;
