/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';

import {
    Typography,
    Card,
  } from '@material-ui/core';

import * as d3 from 'd3';
import * as lodash from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {

    },
    card: {
        boxShadow: '0 2px 2px 0 #BDC9D7',
    },
    graphCard: {
        margin: "5%",
        boxShadow: '0 2px 2px 0 #BDC9D7',
    },
    graphTitle: {
      textAlign: 'center',
      marginTop: '10px',
    },
    graphContainer: {
      
    },
    tooltip: {
      position: 'absolute',
      display: 'none',
      backgroundColor: '#AAAAAA',
      color: 'white',
      paddingLeft: '1%',
      paddingRight: '1%',
      borderRadius: '5px',
      fontFamily: 'roboto'
    }
  }));


const WIDTH = 400,
      HEIGHT = 300;

const margins = { top: 10, bottom: 20, left: 0, right: 0 };

const width = WIDTH - margins.left - margins.right,
      height = HEIGHT - margins.top - margins.bottom

const xScale = d3.scaleBand()
                .range([0, width])
                .padding(0.1),
      yScale = d3.scaleLinear()
                .range([height, 0])

const formatID = (value) => {
    if (typeof value === 'string' || value instanceof String) {
        return value.replace(/\s/g, '');
    } else {
        return value;
    }
}

const filter = (element, selectionIds, selectionLogs) => {
    if (selectionLogs.length && selectionIds.length){
        return {
            value: element.value,
            logs: element.logs.filter((l) => selectionIds.includes(l.user_id) && selectionLogs.includes(l.log_id)),
        }
    } else if (selectionLogs.length) {
        return {
            value: element.value,
            logs: element.logs.filter((l) => selectionLogs.includes(l.log_id)),
        }
    } else if (selectionIds.length) {
        return {
            value: element.value,
            logs: element.logs.filter((l) => selectionIds.includes(l.user_id)),
        }
    } 
}

const filterBarLogs = (logsObject) => {
    const filteredKeys = Object.keys(logsObject).filter((k) => ["bar", "arc"].includes(k.substring(0, 3)));

    const filtered = filteredKeys.reduce((obj, key) => {
                                            obj[key] = logsObject[key];
                                            return obj;
                                        }, {});
    return filtered
}

const filterBodyUsers = (usersObject) => {
    const filteredKeys = Object.keys(usersObject).filter((k) => k.substring(0, 4) === "body");

    const filtered = filteredKeys.reduce((obj, key) => {
                                            obj[key] = usersObject[key];
                                            return obj;
                                        }, {});
    return filtered
}

const yExtent = (values) => {
    const max = d3.max(values);
    let step;
    if (max >= 100) {
        step = 20
    } else if (max >= 50) {
        step = 10
    } else if (max >= 10) {
        step = 5
    } else if (max >= 2) {
        step = 2
    } else {
        step = 1
    }
    return [max / step, [0, Math.ceil( max / step ) * step]];
}

const tooltipPos = (element, name) => {
    const bbox = element.getBBox();
    const svgBox = d3.select(`svg#${name}`).node().getBoundingClientRect();
    
    var posX = (bbox.x + bbox.width/2),
        posY = (bbox.y - 10);
    
    posX = svgBox.x + (posX / WIDTH) * svgBox.width;
    posY = svgBox.y + (posY / HEIGHT) * svgBox.height;
    return [posX, posY];
  }

const commonElements = (array1, array2) => {
    let result = []
    array1.forEach(d => {
        if (array2.indexOf(d) >= 0) {
            result.push(d);
        }
    })
    return result
}

const filterCriteria = (selected) => {
    let bodyIds = Object.values(filterBodyUsers(selected.patients));

    const barLogs = Object.values(filterBarLogs(selected.logs));
    
    let selectedLogs = [];
    if (barLogs.length){
        selectedLogs = barLogs[0]
        barLogs.forEach((arr) => {
            selectedLogs = commonElements(selectedLogs, arr);
        })
    }
    
    let listIds;
    if (Object.keys(selected.patients).includes('patientsList')) {
        listIds = Object.values(selected.patients.patientsList);
    } else {
        listIds = [];
    };

    bodyIds = lodash.flattenDeep(bodyIds);

    let selectedIds = bodyIds.concat(listIds)

    selectedIds = lodash.flattenDeep(selectedIds)
    selectedIds = selectedIds.filter((e, i) => selectedIds.indexOf(e) === i);
    selectedLogs = selectedLogs.filter((e, i) => selectedLogs.indexOf(e) === i);
    return [selectedIds, selectedLogs]
}

const BarChart = (props) => {
    const {data, 
           name, 
           patientCount,
           selectedUsers,
           setSelectedUsers,
           title} = props;

    const classes = useStyles();

    useEffect(() => {

        const selectedKeys = Object.keys(selectedUsers.logs);

        const [selectedIds, selectedLogs] = filterCriteria(selectedUsers);

        let filtered;

        if (selectedIds.length || selectedLogs.length) {
            filtered = data.map((d) => filter(d, selectedIds, selectedLogs));
        } else {
            filtered = data;
        }

        const xValues = filtered.map((d) => d.value)
        const yValues = filtered.map((d) => d.logs.length)

        if (!yValues.filter((x) => x).length) {
            d3.select(`.no-data-${name}`)
                .attr("display", "visible")
                .style('font-family', 'roboto')
        } else {
            d3.select(`.no-data-${name}`).attr("display", "none")
        }

        const svg = d3.select(`#${name}`);

        const g = svg.select('g.container'),
              xAxis = svg.select('g.xAxis')
                            .style('font-size', '1rem')
                            .style('font-family', 'roboto'),
              yAxis = svg.select('g.yAxis')
                            .style('font-family', 'roboto')
                            .style('font-size', '1rem');

        xScale.domain(d3.range(xValues.length));
        const [step, extent] = yExtent(yValues);
        
        yScale.domain(extent);
        
        xAxis.transition().call(d3.axisBottom(xScale).tickFormat(i => xValues[i]));
        yAxis.transition().call(d3.axisLeft(yScale).ticks(step).tickSize(-width));
        
        xAxis.selectAll("line").remove()
        xAxis.select(".domain").remove()
        xAxis.selectAll("text")
            .attr("font-weight", "bold")
            .attr("dy", "0.45em")

        yAxis.select(".domain").remove()
        yAxis.selectAll("text").remove()
        yAxis.selectAll('line').style("opacity", 0.3);

        
        const mouseOver = (e, d) => {
            const [ cx, cy ] = tooltipPos(e.currentTarget, name);

            const patients = d.logs.length
            const plural = patients === 1 ? 'reporte' : 'reportes';
            d3.select(`#${name}-tooltip`)
                .style('display', 'block')
                .style('left', `${cx}px`)
                .style('top', `${cy}px`)
                .select('p')
                    .html(`${patients} ${plural}`)
            d3.select(`#${name}-rect-${formatID(d.value)}`)
                .style('opacity', 0.5)
                .attr('opacity', 0.5);
        }
      
        const mouseLeave = (e, d) => {
            d3.select(`#${name}-rect-${formatID(d.value)}`)
                .style('opacity', 1)
                .attr('opacity', 1);
            
            d3.select(`#${name}-tooltip`)
                .style('display', 'none')
        }

        const click = (_, d) => {
            const tag = `bar-${name}${formatID(d.value)}`;
            if (Object.keys(selectedUsers.logs).includes(tag)) {       
                const newSelected = lodash.cloneDeep(selectedUsers);
                delete newSelected.patients[tag];
                delete newSelected.logs[tag];
                setSelectedUsers(newSelected);
            } else {           
                const newSelected = lodash.cloneDeep(selectedUsers);
                const fulldatum = data.find((dt) => dt.value === d.value);
                newSelected.patients[tag] = fulldatum.logs.map((l) => l.user_id);
                newSelected.logs[tag] = fulldatum.logs.map((l) => l.log_id);
                setSelectedUsers(newSelected);
            }
        }

        const updateBar = (d, i) => {
            let [rx, ry] = [0, 0];
            let initialYPosition;
            let verticalTranslation;
            if (d.logs.length) {
                rx = 5;
                ry = 5;
            }
            if (extent[1]) {
                initialYPosition = yScale(d.logs.length) + ry
                verticalTranslation = height - yScale(d.logs.length) - ry;
            } else {
                initialYPosition = height;
                verticalTranslation = 0;
            }
            return `
                M${xScale(i)},${initialYPosition}
                a${rx},${ry} 0 0 1 ${rx},${-ry}
                h${xScale.bandwidth() - 2 * rx}
                a${rx},${ry} 0 0 1 ${rx},${ry}
                v${verticalTranslation}
                h${-(xScale.bandwidth())}Z
                `
        }

        g.selectAll("path.bar")
            .data(filtered, (d) => d.value)
            .join(
                (enter) => {
                    enter.append('path')
                        .attr('class', 'bar')
                        .attr("d", (_, i) => `
                            M${xScale(i)},${yScale(0)}
                            a0,0 0 0 1 0,0
                            h${xScale.bandwidth()}
                            a0,0 0 0 1 0,0
                            v${height - yScale(0)}
                            h${-(xScale.bandwidth())}Z
                        `)
                        .attr("id", (d) => `${name}-rect-${formatID(d.value)}`)
                        .attr("fill", (d) => {
                            const tag = `bar-${name}${formatID(d.value)}`;
                            if (selectedKeys.includes(tag)) {
                                return 'steelblue'
                            } else {
                                return '#802e87'
                            }
                        })
                        .on("mouseover", mouseOver)
                        .on("mouseleave", mouseLeave)
                        .on("click", click)
                        .transition()
                            .attr("d", updateBar)
                    },
                (update) => {
                    update.transition()
                            .attr("d", updateBar)
                    update.attr("fill", (d) => {
                                const tag = `bar-${name}${formatID(d.value)}`;
                                if (selectedKeys.includes(tag)) {
                                    return 'steelblue'
                                } else {
                                    return '#802e87'
                                }
                            })
                    update.on("click", click)
                          .on("mouseover", mouseOver)
                          .on("mouseleave", mouseLeave);
                },
                (exit) => {
                    exit.remove()
                }
            )
                
    }, [data, selectedUsers, patientCount])

    return (
        <Card
            className={classes.card}
        >
            <Typography
                component="h2"
                gutterBottom
                variant="overline"
                className={classes.graphTitle}
            >
            {title}
            </Typography>
            <Card
                className={classes.graphCard}
            >
                <div className={classes.graphContainer}>
                    <div className={classes.tooltip} id={`${name}-tooltip`}>
                    <p></p>
                    </div>
                    <svg id={name} version="1.0" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox={`0 0 ${WIDTH} ${HEIGHT}`} preserveAspectRatio="xMidYMid meet">
                        <g className='container' transform={`translate(${margins.left}, ${margins.top})`}></g>
                        <g className={`no-data-${name}`} display="none">
                            <text x={`${WIDTH/2}`} y={`${HEIGHT/2}`} fill="currentColor" textAnchor="middle" >No hay Autorreportes</text>
                        </g>
                        <g className='xAxis' transform={`translate(${margins.left},${HEIGHT - margins.bottom})`}></g>
                        <g className='yAxis' transform={`translate(${margins.left}, ${margins.top})`}></g>
                    </svg>
                </div>
            </Card>
        </Card>
        
              
        
    )
  }
  
  export default BarChart;
  