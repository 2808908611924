import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getLog(logId) {
  const accessToken = await localStorage.getItem("currentToken");
  const response = await fetch(`${URL}/logs/${logId}/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
  });
  const res = await response.json();
  if (checkResponse(response)) {
    return { response, res };
  }
}
