import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function deleteSpecialty(id) {
    const accessToken = localStorage.getItem('currentToken')
    const response = await fetch(`${URL}/specialties/${id}/`, {
        
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        }
    });
    
    if (checkResponse(response)) {
        return  response;
        }
}