import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function postMessage(data, accessToken) {
    const response = await fetch(`${URL}/messages/`, {
        
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${accessToken}`
        },
        body: JSON.stringify({
            receiver: data.receiver,
            content: data.content,
            subject: data.subject
        })
    });
    if (checkResponse(response)) {
        return  response;
        }
    
}