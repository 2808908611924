import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function getAutoreportesUser(accessToken, id, params) {
  try {
    const end = new Date();
    const start = new Date();
    start.setMonth(end.getMonth() - 1);

    const defParams = {
      date_range: [
        start.toISOString().slice(0, 10),
        end.toISOString().slice(0, 10),
      ],
    };
    let searchParams = defParams;

    if (params) {
      searchParams = params;
    }

    const queryParams = new URLSearchParams(searchParams);
    const query = queryParams.toString();

    const response = await fetch(`${URL}/users/${id}/logs/?${query}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    });
    const res = await response.json();
    if (checkResponse(response)) {
      return res;
    }
  } catch (error) {
    alert(error);
    return null;
  }
}
