import { URL } from '../url'
import { checkResponse} from "../checkResponse";

export async function actualizarTareas(lista) {
    const accessToken = localStorage.getItem('currentToken')
    const id =  localStorage.getItem('id').toString()
    const response = await fetch(`${URL}/users/${id}/tasks/`, {
    
        method: 'PATCH',
        headers: {
            'Authorization': `Token ${accessToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "tasks": lista
        })
    
    });
    const res = await response.json();
    if (checkResponse(res)) {
        return  {response, res};
        }
}